import styled from "@emotion/styled";

export const PreviousRecomandationBox = styled("div", {
  shouldForwardProp: (prop) => prop !== "excessdata",
})(
  ({ excessdata }: { excessdata: Number }) =>
    ` max-width: 600px;
    width: 100%;
    margin: auto;
    min-height:${excessdata > 11 ? "880px" : "0px"};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-height: 640px) {
      min-height:${excessdata > 7 ? "843px" : "0px"};
    }

`
);

export const PreviousRecomandationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  padding-bottom: 20px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  max-width: 96px;
  width: 100%;
  margin-top: 5%;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
