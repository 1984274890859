import { useNavigate } from "react-router-dom";
import GetUser from "../../Utils/Get-user";

import newIcon from "../../assets/images/svg/Create.svg";
import paperworkIcon from "../../assets/images/svg/Folder.svg";
import trackIcon from "../../assets/images/svg/Overview.svg";

import { useGetExcessId } from "../../Redux/api/User";

import { createDealRoute, supplierUploadRoute, trackOffersRoute } from "../../Routes/constants";

import {
  DataPreviewBox,
  DataPreviewText,
  ContentText,
  DashboardCard,
  DashboardContainer,
  DashboardHead,
  DashboardDesc,
  DashboardButton,
} from "./styles";

const Dashboard = () => {
  const navigate = useNavigate();
  const { data: { data: { excess = [] } = {} } = {} } = useGetExcessId(
    GetUser()
  );

  const handleStart = () => {
    navigate(supplierUploadRoute);
  };

  const handleCreate = () => {
    navigate(createDealRoute);
  }

  const handleTrack = () => {
    navigate(trackOffersRoute);
  }

  return (
    <>
      <DataPreviewBox>
        <div>
          <DataPreviewText>Home</DataPreviewText>
          <ContentText>
            Get Started Here
          </ContentText>
          <DashboardContainer>
            <DashboardCard>
            
              <DashboardHead>
              <img src={newIcon} alt="New" />
              <br/>
                New Offers
              </DashboardHead>
              <DashboardDesc>
                Create an offer or link storage system
              </DashboardDesc>
              <DashboardButton onClick={handleStart}> Start </DashboardButton>
            </DashboardCard>

            <DashboardCard>
              <DashboardHead>
              <img src={paperworkIcon} alt="Folder" />
              <br/>
                Existing Offers
              </DashboardHead>
              <DashboardDesc>
                Use paperwork tooling
              </DashboardDesc>
              <DashboardButton onClick={handleCreate}> Start </DashboardButton>
            </DashboardCard>

            <DashboardCard>
              <DashboardHead>
              <img src={trackIcon} alt="Track" />
              <br/>
                Track Offers
              </DashboardHead>
              <DashboardDesc>
                View all existing offers
              </DashboardDesc>
              <DashboardButton onClick={handleTrack}> Track </DashboardButton>

            </DashboardCard>
            
          </DashboardContainer>
        </div>
      </DataPreviewBox>
    </>
  );
};

export default Dashboard;
