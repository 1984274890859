import { styled } from "@mui/material";

export const NavbarBrand = styled("div")`
  display: flex;
  align-content: center;
  color: black;

  margin-right: 18px;

  .navbar-brand {
    font-weight: bold;
    color: inherit !important;
    font-family: "ProximaNova" !important;
    font-size: 16px;
    margin: 0;
    padding: 10px 18px;
  }
  &:hover {
    background-color: black;
    color: white;
  }
`;

export const NavbarSignUp = styled("div")`
  display: flex;
  align-content: center;
  margin-left: 18px;

  .navbar-brand {
    background-color: black;
    font-weight: bold;
    color: white !important;
    font-family: "ProximaNova" !important;
    font-size: 16px;
    padding: 12px;
    margin: 0;
  }
`;
