import styled from "@emotion/styled";

export const DataPreviewBox = styled("div")`
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 740px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 1280px) and (min-width: 768px) {
    min-height: 620px;
  }
  margin-top: -70px;

`;

export const DataPreviewText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ItemNameText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;


export const ConfirmationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ListText = styled("div")`
  font-weight: 700;
  line-height: 25px;
`;


export const PreviewBox = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentText = styled("div")`
  color: #48B7A7;
  font-size: 22px;
  font-family: "ProximaNova-Medium" !important;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const NeoDiv = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const InfoCard = styled("div")`
  border: none;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 5px rgba(0,0,0,.1);
`

export const MoneyText = styled("span")`
  color: #41C175;
`;

export const DescHeader = styled("div")`
  color: #343434;
  font-weight: 700;
`;

export const DescText = styled("div")`
  color: #5E5E5E;
`;

export const Datatable = styled("div")`
  gap: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  max-width: 692px;
  width: 100%;
  @media (max-width: 1280px) and (min-width: 768px) {
    gap: 50px;
    margin-top: 50px;
  }
  @media (max-width: 393px) {
    gap: 45px;
    margin-top: 44px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ContextHeading = styled("div")`
  margin-top: 102px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 25px;
  border: solid 1px #e1e1e1;
  background-color: #fff;
  text-align: center;
}
`;

export const HeadingText = styled("div")`
  width: 173px;
  height: 42px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 5px;
}
`;

export const ButtonBox = styled("div")`
  max-width: 900px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImgContainer = styled("img")`
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
`;

export const DataUploadBox = styled("div")`
  max-width: 680px;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    min-height: 425px;
  }
`;

export const DataUploadText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const UploadBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;