import React from "react";
import SubMenuList from "./SubMenuList";

interface SideBarMenuListProp {
  menuItem: any;
}

interface item {
  name: string;
}

const SideBarMenuList = ({ menuItem }: SideBarMenuListProp) => {
  return (
    !!menuItem?.length &&
    menuItem?.map((item: item, index: number) => (
      <SubMenuList subList={item} key={item.name + index} />
    ))
  );
};

export default SideBarMenuList;
