import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
//import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import Carousel from "react-bootstrap/Carousel";
import { Grid } from "@mui/material";
// @ts-ignore
import crypto from "crypto-browserify";

import { loginSuccess } from "../../Redux/login/reducer";
import {
  useLazyGetUserNameQuery,
  usePostLoginMutation,
  usePostGoogleLoginMutation,
  useLazyGetBusinessinfo,
} from "../../Redux/api/User";
import { authUser } from "../../Redux/auth/reducer";

import NavBar from "../Navbar";
import GetUser from "../../Utils/Get-user";

import {
  BusinessinfoRoute,
  RegisterRoute,
  forgotpasswordRoutes,
} from "../../Routes/constants";

import larlogo from "../../assets/images/signup_graphics/logo-beta.png";
import img1 from "../../assets/images/signup_graphics/financial _tracking.png";
import img2 from "../../assets/images/signup_graphics/expensetracker.png";
import img3 from "../../assets/images/signup_graphics/pricingdiscovery.png";
import img4 from "../../assets/images/signup_graphics/analyticsandoptimization.png";

import {
  LeftPanel,
  CarouselBox,
  RightPanel,
  LoginForm,
  LoginButton,
  NavbarBox,
} from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styling/login.css";

const GOOGLE_LOGIN_HASH_KEY = "snJ5K#WagH2Zvkg4";

const useStyles = makeStyles(() => ({
  root: {
    background: "#dff5e4",
    textAlign: "center",
  },
  drawer: {
    textAlign: "center",
  },
  drawerPaper: {
    width: "16.67%",
    textAlign: "center",
  },
  logo: {
    width: 125,
    height: 45,
    flexGrow: 0,
    padding: "0 0 1.4px",
  },
  logoBox: {
    display: "flex",
    margin: "10px",
    marginTop: "30px",
    marginRight: "auto",
    marginLeft: "10%",
  },
  mobileLogoBox: {
    display: "flex",
    justifyContent: "center",
  },
  logoIn: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 43.4,
  },
  logoText: {
    textAlign: "center",
    width: "200%",
    marginTop: "60px",
    marginBottom: "60px",
    fontSize: 18,
  },
  signInButton: {
    width: 311,
    height: 38,
    flexGrow: 0,
    padding: "10px 50px 10px 70px !important",
    marginTop: 24,
    borderRadius: "5px !important",
    boxShadow:
      " 0 2px 3px 0 rgba(0, 0, 0, 0.17), 0 0 3px 0 rgba(0, 0, 0, 0.08) !important",
    backgroundColor: "#FFF",
    "& div": {
      padding: "4px !important",
    },
    "& span": {
      padding: "0px !important",
      fontWeight: "800 !important",
      fontSize: "15px !important",
    },
  },
  form: {
    marginTop: 40,
  },
  mobileView: {
    width: 392,
    height: 851,
    flexGrow: 0,
    padding: "64px 40px 305px",
  },
  mobileViewTextBox: {
    marginTop: 8,
    color: "#b8b8b8",
    height: 48,
  },
  registerSection: {
    display: "flex",
    flexDirection: "column",
    fontSize: 17,
    fontWeight: 600,
    marginTop: 40,
    textAlign: "center",
    "& a": {
      color: "#48b7a7 !important",
      fontWeight: "bold",
      marginLeft: 6,
    },
  },
  logoTextBox: {
    display: "flex",
    justifyContent: "center",
  },
  optionText: {
    fontSize: 15,
    margin: "15px 0px 5px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    "@media(max-width: 768px)": {
      textAlign: "center",
    },
  },
  forgotPassword: {
    fontSize: 15,
    margin: "2px 0px 6px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    "@media(max-width: 768px)": {
      textAlign: "center",
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login]: any = usePostLoginMutation();
  const [googlelogin]: any = usePostGoogleLoginMutation();
  const [getuserName]: any = useLazyGetUserNameQuery();
  const [getBusinessInfo]: any = useLazyGetBusinessinfo();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    setEnableButton(email.length > 0 && password.length > 6);
  }, [email.length, password.length]);

  const handleSubmit: any = async (e: any) => {
    e.preventDefault();
    setPassword("");
    const regExpEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (regExpEmail.test(email)) {
      try {
        const {
          data: { token },
        } = await login({ email, password });
        localStorage.setItem("token", token);
        dispatch(loginSuccess({ email }));

        const { data: businessinfo } = await getBusinessInfo(GetUser());

        if (businessinfo?.filled) {
          const { data: userinfo } = await getuserName(GetUser());
          localStorage.setItem("FirstName", userinfo?.firstName);
          localStorage.setItem("LastName", userinfo?.lastName);
          dispatch(authUser({ isLoggedIn: true }));
        } else {
          navigate(BusinessinfoRoute, { replace: true });
        }
      } catch (err) {}
    } else {
      toast.error("Please type correct email");
    }
  };
  const responseGoogle = async (response: any) => {
    const creds = response.credential;

    if (creds) {
      try {
        const userInfo: any = jwt_decode(creds);
        const payload: any = {
          email: userInfo.email,
          googleId: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          log_token: crypto
            .createHash("sha256")
            .update(
              GOOGLE_LOGIN_HASH_KEY +
                userInfo.sub +
                userInfo.email +
                userInfo.given_name
            )
            .digest("hex"),
        };
        const {
          data: { token },
        } = await googlelogin(payload);
        localStorage.setItem("token", token);
        dispatch(loginSuccess({ email }));

        const { data: businessinfo } = await getBusinessInfo(GetUser());

        if (businessinfo?.filled) {
          const { data: userinfo } = await getuserName(GetUser());
          localStorage.setItem("FirstName", userinfo?.firstName);
          localStorage.setItem("LastName", userinfo?.lastName);
          dispatch(authUser({ isLoggedIn: true }));
        } else {
          navigate(BusinessinfoRoute, { replace: true });
        }
      } catch (err) {}
    }
  };
  return (
    <>
      <div style={{ height: "100vh" }}>
        <div style={{ height: "100vh" }}>
          <Grid
            container
            spacing={0}
            sx={{ height: "100%" }}
            className="Login-form"
          >
            <Grid item xs={0} sm={6} md={7} lg={7} className="Loginform">
              <LeftPanel>
                <div className={classes.logoBox}>
                  <a href="https://www.sotira.co/">
                    <img alt="logo" className={classes.logo} src={larlogo} />
                  </a>
                </div>
                <div>
                  <div className={classes.logoTextBox}></div>
                  <CarouselBox className="Login">
                    <Carousel
                      pause="hover"
                      className="carousel-content "
                      bsPrefix="carousel"
                      indicators={true}
                      fade={true}
                      nextLabel={null}
                      prevLabel={null}
                      slide={false}
                      activeIndex={index}
                      onSelect={handleSelect}
                    >
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img1} alt="First slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <strong>
                              <h5>
                                Discover how much cash you can unlock for your
                                excess inventory
                              </h5>
                            </strong>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img2} alt="Second slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Connect with a vetted network of private buyers
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img3} alt="Third slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Convert dead inventory to working capital within a
                              week
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img4} alt="Fourth slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Assess and compare profitable and efficient
                              offloading options
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </CarouselBox>
                </div>
              </LeftPanel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}
              className="login-right-wrapper"
            >
              {/* this is the side Login */}

              <RightPanel>
                <div className="mobileLogoBox">
                  <img alt="logo" className={classes.logo} src={larlogo} />
                </div>
                <LoginForm>
                  <Form className="signinform">
                    <NavbarBox>
                      <NavBar />
                    </NavbarBox>
                    <h2 className="titleLog"> Log In </h2>
                    <Form.Group className="form-field">
                      <GoogleLogin
                        size="large"
                        width="170"
                        onSuccess={responseGoogle}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="form-field">
                      <Form.Label className="title"> Email</Form.Label>
                      <Form.Control
                        autoFocus
                        type="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="form-field">
                      <Form.Label className="title">Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <LoginButton
                      disabled={!enableButton}
                      onClick={handleSubmit}
                    >
                      Login
                    </LoginButton>
                    <div className={classes.optionText}>
                      Don't have an account?
                      <a
                        style={{ color: "#89D0C6", textDecoration: "none" }}
                        href={RegisterRoute}
                      >
                        {" "}
                        Register here{" "}
                      </a>
                    </div>
                    <div className={classes.forgotPassword}>
                      Forgot password?
                      <a
                        style={{ color: "#89D0C6", textDecoration: "none" }}
                        href={forgotpasswordRoutes}
                      >
                        {" "}
                        Click Here
                      </a>
                    </div>
                  </Form>
                </LoginForm>
              </RightPanel>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Login;
