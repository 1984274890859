import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  email: "",
  password: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    logoutUserSuccess(state) {
      state.email = "";
      state.password = "";
      localStorage.removeItem("token");
      localStorage.removeItem("FirstName");
      localStorage.removeItem("LastName");
    },
  },
});

export const { loginSuccess, logoutUserSuccess } = loginSlice.actions;

export default loginSlice.reducer;
