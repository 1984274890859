import { useState, useEffect, useRef } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListItem, Typography } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import { supplierUploadRoute } from "../../Routes/constants";
import { usePostQuestionAnswerMutation } from "../../Redux/api/User";

import { Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';

import {
  question1,
  question2,
} from "./onboardingConstants";
import GetUser from "../../Utils/Get-user";

import {
  DataPreviewBox,
  DataPreviewText,
  ContentText,
  NextButton,
  ListStyle,
  QuestionList,
  ButtonBox,
  BackButton,
  TextBox,
  StyledTextField,
  RecomandationText,
  ProductTypeQuestion,
  ProductTypeQuestionList,
  OptionSelectText,
  DocuBorder, 
  PdfBox, 
  DocuDiv, 
  ContextHeading, 
  HeadingText,
  ConfirmationText, 
  CheckboxContainer, 
  CheckboxLabel,
} from "./styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

interface questionanswer {
  label: string;
  value: number;
}

interface activesteps {
  setActiveStep: (step: number) => void;
  activeStep: number;
}

interface Location {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  pickup?: boolean;
  schedule?: [];
}

const steps = [
  "Terms of Service",
  "Add Warehouse Location",
];

const Question = ({ setActiveStep, activeStep }: activesteps) => {
  const navigate = useNavigate();
  const classes = ListStyle();

  const filterProductvalue = (inputArray: string[], otherType: string) => {
    return inputArray.map((item: string) =>
      item === "Other" ? otherType : item
    );
  };

  const [QuestionAnswer]: any = usePostQuestionAnswerMutation();

  const [productType, setProductType] = useState<string[]>([]);
  const [otherProductType, setOtherProductType] = useState<string>("");
  const [salesValue, setSalesValue] = useState("");
  const [options, setOptions] = useState<string[]>([]);

  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const [isChecked, setIsChecked] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [eSignatureCompany, setESignatureCompany] = useState("");
  const [eSignatureName, setESignatureName] = useState("");
  const [eSignatureTitle, setESignatureTitle] = useState("");
  const [accept, setAccept] = useState(false);

  const dates = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [pickupSchedule, setPickupSchedule] = useState<{ [key: string]: { start: any, end: any } }>({});
  const [pickupDates, setPickupDates] = useState([]);
  const sortedPickupDates = pickupDates.sort((a, b) => dates.indexOf(a) - dates.indexOf(b));

  const [isPickup, setIsPickup] = useState(false);

  const [location, setLocation] = useState<Location>({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    pickup: false,
    schedule: []
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_PLACES_TEST || '',
    libraries: ['places'],
  });

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const autocompleteRef = useRef<any>(null);

  //var url = 'https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/66aad3b633391259ac5702d0_Buyer-Agreement-for-Sotira.pdf';
  var url = 'https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/6702f839e24c2ee00dd1ea6a_Supplier%20Agreement%20(Sotira).pdf';

  const handleScroll = () => {
    const container = pdfContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setScrollEnd(true);
        setScrolled(true);
      } else {
        setScrollEnd(false);
      }
    }
  };

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  
  const acceptTOS = () => {
    if(isChecked && eSignatureName !== "" && eSignatureCompany !== "" && eSignatureTitle !== "" && scrolled) {
      return false
    } else {
      return true
    }
  }

  const isLocation = () => {
    if(location.address !== "" && location.name !== "") {
      return false
    } else {
      return true
    }
  }

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      console.log(place);
      
      if (place) {
        const addressComponents = place.address_components || [];
        
        const locationDetails: Location = {
          name: place.name || '',
          address: '',
          city: '',
          state: '',
          zip: '',
        };

        const formattedAddressParts = place.formatted_address?.split(',') || [];
        console.log(formattedAddressParts);
        if (formattedAddressParts.length > 0) {
          locationDetails.address = formattedAddressParts[0]; // Assuming address is the first part
        }

        addressComponents.forEach((component: google.maps.GeocoderAddressComponent) => {
          if (component.types.includes('locality')) {
            locationDetails.city = component.long_name;
          }
          if (component.types.includes('administrative_area_level_1')) {
            locationDetails.state = component.short_name;
          }
          if (component.types.includes('postal_code')) {
            locationDetails.zip = component.long_name;
          }
        });

        setLocation(locationDetails);
      }
    }
  };

  const handleSubmit = async (e: any) => {
    console.log('submit');
    const params = {
      userID: GetUser(),
      name: eSignatureName,
      company: eSignatureCompany,
      title: eSignatureTitle,
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'user/add_onboard_tos', params).then((response) => {
        toast.success("Terms of service signed!", {
          position: "top-right",
          autoClose: 1500,
        });
        console.log(response);
        setActiveStep(activeStep + 1);
      });
    } catch (error) {
      console.error("Error Signing TOS:", error);
    }
  }

  const addLocation = async () => {
    try {
      const formattedPickupSchedule = Object.entries(pickupSchedule).map(([day, times]) => ({
        day,
        start: times.start,
        end: times.end,
      }));
      const params = {
        location: location,
        times: formattedPickupSchedule,
        userID: GetUser()
      };
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'user/add_onboard_loc', params).then((response) => {
        toast.success("Warehouse Location has been added", {
          position: "top-right",
          autoClose: 1500,
        });
      });
    } catch (error) {
      console.error("Error Adding Location:", error);
    }
    try {
      const onboard_params = {
        userID: GetUser()
      };
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'user/onboarding_done', onboard_params).then((response) => {
        console.log(response);
        toast.success("Onboarding Completed!", {
          position: "top-right",
          autoClose: 3000,
        });
      });
      //await QuestionAnswer(payload);
      setTimeout(() => {
        navigate(supplierUploadRoute);
      }, 1000);
    } catch (err) {}
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    const container = pdfContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    } else {
      console.log("Container not found");
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const style = document.createElement('style');
      style.innerHTML = `
        .pac-container {
          z-index: 1500; /* Ensure dropdown is on top */
          position: absolute; /* Position dropdown correctly */
        }
      `;
      document.head.appendChild(style);
      
      // Cleanup function to remove the style when the component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isLoaded]);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    var newDates: any = typeof value === 'string' ? value.split(',') : value;

    const updatedSchedule = { ...pickupSchedule };
    const removedDates = pickupDates.filter((date) => !newDates.includes(date));
    removedDates.forEach((date) => {
      delete updatedSchedule[date];  
    });

    newDates.forEach((date:any) => {
      if (!updatedSchedule[date]) {
        updatedSchedule[date] = { start: null, end: null };
      }
    });

    setPickupDates( newDates );
    setPickupSchedule(updatedSchedule);
  };

  const handleDateTimeChange = (date: string, type: 'start' | 'end', time: any) => {
    setPickupSchedule(prevSchedule => ({
      ...prevSchedule,
      [date]: {
        ...prevSchedule[date],
        [type]: time
      }
    }));
    console.log(pickupSchedule);
  };

  const showLocation = () => {
    if (loadError) {
      return <div>Error loading location services</div>;
    }
  
    if (!isLoaded) {
      return <div>Loading...</div>;
    }

    return (
      <div>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          id="autocomplete"
          className="input-field"
          type="text"
          placeholder="Search for a place"
        />
      </Autocomplete>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isEditable}
            onChange={(e) => setIsEditable(e.target.checked)}
          />
          {" "}Edit
        </label>
      </div>
      <div>
        <label>
          Name:
          <input
            name="name"
            value={location.name}
            placeholder="Name"
            onChange={(e) => setLocation(prevLocation => ({ ...prevLocation, name: e.target.value }))}
            disabled={!isEditable}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          Address:
          <input
            name="address"
            value={location.address}
            placeholder="Street Address"
            onChange={(e) => setLocation(prevLocation => ({ ...prevLocation, address: e.target.value }))}
            disabled={!isEditable}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          City:
          <input
            name="city"
            value={location.city}
            placeholder="City"
            onChange={(e) => setLocation(prevLocation => ({ ...prevLocation, city: e.target.value }))}
            disabled={!isEditable}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          State:
          <input
            name="state"
            value={location.state}
            placeholder="State"
            onChange={(e) => setLocation(prevLocation => ({ ...prevLocation, state: e.target.value }))}
            disabled={!isEditable}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          Zip Code:
          <input
            name="zip"
            value={location.zip}
            placeholder="Zip Code"
            onChange={(e) => setLocation(prevLocation => ({ ...prevLocation, zip: e.target.value }))}
            disabled={!isEditable}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isPickup}
            onChange={(e) => setIsPickup(e.target.checked)}
          />
          {" "} Can pickup from this location
        </label>
      </div>
      { isPickup &&
        <div>
          <div>
            <InputLabel id="multiple-date-label">Select Pickup Dates</InputLabel>
            <Select
              labelId="multiple-date-label"
              id="multiple-date"
              multiple
              value={pickupDates}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
            >
              {dates.map((date) => (
                <MenuItem
                  key={date}
                  value={date}
                >
                  {date}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
          {sortedPickupDates.length > 0 &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {sortedPickupDates.map((pickup) => (
              <div key={pickup}>
                <br />
              {pickup} {" "}
              <br/>
              <TimePicker
                label={`${pickup} Start Time`}
                value={pickupSchedule[pickup]?.start || null}
                onChange={(time) => handleDateTimeChange(pickup, 'start', time)}
              />
              <TimePicker
                label={`${pickup} End Time`}
                value={pickupSchedule[pickup]?.end || null}
                onChange={(time) => handleDateTimeChange(pickup, 'end', time)}
              />
            </div>
            ))}
         </LocalizationProvider>
          }
          </div>
        </div>
      }
      <br />
      <NextButton  onClick={addLocation} disabled={isLocation()}>Add and Complete</NextButton>
      </div>
    )
  }


  const showTOS = () => {

    if(!accept) {
      
      return (
        <>

        <DocuDiv>
          <PdfBox ref={pdfContainerRef}>
          <DocuBorder file= {url} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
          </DocuBorder>
          </PdfBox>
          <br/>
          {scrolled && 
          <div>
          <input
            type="text"
            value={eSignatureName}
            onChange={(e) => setESignatureName(e.target.value)}
            placeholder="Name"
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '5px',
              border: '1px solid #ccc'
            }}
          />
          
          <input
            type="text"
            value={eSignatureCompany}
            onChange={(e) => setESignatureCompany(e.target.value)}
            placeholder="Company"
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '5px',
              border: '1px solid #ccc'
            }}
          />
          
          <input
            type="text"
            value={eSignatureTitle}
            onChange={(e) => setESignatureTitle(e.target.value)}
            placeholder="Title"
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '5px',
              border: '1px solid #ccc'
            }}
          />
          </div>
          }
          <CheckboxContainer>
            <input 
              type="checkbox" 
              checked={isChecked} 
              onChange={handleCheckboxChange} 
            />
            <CheckboxLabel>I have read and agree to the terms of service</CheckboxLabel>
          </CheckboxContainer>
          <br/>
          <NextButton onClick={handleSubmit} disabled={acceptTOS()}>
            I agree
          </NextButton>
          </DocuDiv>
        </>
      );
    } else {
      <DocuDiv>

        <ConfirmationText>
          Read our&nbsp;<a href="https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/66aad3b633391259ac5702d0_Buyer-Agreement-for-Sotira.pdf" target="_blank" rel="noreferrer">Supplier terms of service </a>&nbsp;and sign below.
        </ConfirmationText>
        <div>
          Terms of service has already been agreed to, if there are any issues contact <a href="mailto:support@sotira.co">support@sotira.co</a>
        </div>
      </DocuDiv>
      
    }
  } 

  

  return (
    <>
      <DataPreviewBox step={activeStep}>
        <DataPreviewText> Onboarding Questions </DataPreviewText>
        {activeStep === 0 && (
          <RecomandationText>
            Please view terms of service and sign to proceed.
          </RecomandationText>
        )}

        {activeStep === 1 && (
          <RecomandationText>
            Add the pickup location of your warehouse. This can be changed in Settings at any time.
          </RecomandationText>
        )}

        <QuestionList step={activeStep}>
          <List className={classes.list}>
            {activeStep === 0 ? (
              <div>
                {showTOS()}
              </div>
            ) : activeStep === 1 ? (
              <>
              <div>
                {showLocation()}
              </div>
              </>
            ) : null}
          </List>
        </QuestionList>
      </DataPreviewBox>
      <ButtonBox step={activeStep}>
        {activeStep > 0 && <BackButton onClick={handleBack}>Back</BackButton>}
        {activeStep === steps.length - 1 &&
          <div></div>
        }
      </ButtonBox>
    </>
  );
};
export default Question;
