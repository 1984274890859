import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Document, Page, pdfjs } from 'react-pdf'
import { ConfirmationText, NextButton, CheckboxContainer, CheckboxLabel } from "./styles";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import {DocuBorder, PdfBox, DocuDiv} from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

export default function TPopup(props: any) {
  const [isChecked, setIsChecked] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [eSignatureCompany, setESignatureCompany] = useState("");
  const [eSignatureName, setESignatureName] = useState("");
  const [eSignatureTitle, setESignatureTitle] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [scrollEnd, setScrollEnd] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const container = pdfContainerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setScrollEnd(true);
        setScrolled(true);
      } else {
        setScrollEnd(false);
      }
    }
  };

  useEffect(() => {
    const container = pdfContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    } else {
      console.log("Container not found");
    }
  }, []);


  var url = 'https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/66aad3b633391259ac5702d0_Buyer-Agreement-for-Sotira.pdf';

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e: any) => {
    console.log('submit');
    const params = {
      offerID: props.offerID
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/buyer_accept_tos', params).then((response) => {
        console.log(response);
        window.location.reload();
      });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  }

  const acceptTOS = () => {
    if(isChecked && eSignatureName != "" && eSignatureCompany != "" && eSignatureTitle != "" && scrolled) {
      return false
    } else {
      return true
    }
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }



  
  return (
    <>
      <ConfirmationText>
        Read our&nbsp;<a href="https://uploads-ssl.webflow.com/61dd2460f99ba00b4d9075ec/66aad3b633391259ac5702d0_Buyer-Agreement-for-Sotira.pdf" target="_blank">buyer terms of service </a>&nbsp;and sign below.
      </ConfirmationText>
      <PdfBox ref={pdfContainerRef}>
      <DocuBorder file= {url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
      </DocuBorder>
      </PdfBox>
      <br/>
      <div>
        {scrolled ? '' : 'Please read through the document'}
      </div>
      <br/>
      {scrolled && 
      <div>
      <input
        type="text"
        value={eSignatureName}
        onChange={(e) => setESignatureName(e.target.value)}
        placeholder="Name"
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      />
      
      <input
        type="text"
        value={eSignatureCompany}
        onChange={(e) => setESignatureCompany(e.target.value)}
        placeholder="Company"
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      />
      
      <input
        type="text"
        value={eSignatureTitle}
        onChange={(e) => setESignatureTitle(e.target.value)}
        placeholder="Title"
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }}
      />
      </div>
      }
      <CheckboxContainer>
        <input 
          type="checkbox" 
          checked={isChecked} 
          onChange={handleCheckboxChange} 
        />
        <CheckboxLabel>I have read and agree to the terms of service</CheckboxLabel>
      </CheckboxContainer>
      <br/>
      <NextButton onClick={handleSubmit} disabled={acceptTOS()}>
        I agree
      </NextButton>
    </>
  );
}
