import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authUser(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
  },
});

export const { authUser } = authSlice.actions;

export default authSlice.reducer;
