import { useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Carousel from "react-bootstrap/Carousel";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { makeStyles } from "@material-ui/styles";
// @ts-ignore
import crypto from "crypto-browserify";

import {
  usePostGoogleLoginMutation,
  usePostRegisterMutation,
} from "../../Redux/api/User";

import NavBar from "../Navbar";

import { homeRoute, RegisterRoute } from "../../Routes/constants";

import larlogo from "../../assets/images/signup_graphics/logo-beta.png";
import img1 from "../../assets/images/signup_graphics/financial _tracking.png";
import img2 from "../../assets/images/signup_graphics/expensetracker.png";
import img3 from "../../assets/images/signup_graphics/pricingdiscovery.png";
import img4 from "../../assets/images/signup_graphics/analyticsandoptimization.png";

import {
  LeftPanel,
  CarouselBox,
  RightPanel,
  LoginForm,
  Button,
  NavbarBox,
} from "./styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styling/login.css";
import "../../assets/styling/register.css";

const GOOGLE_LOGIN_HASH_KEY = "HASHKEY";

const useStyles = makeStyles(() => ({
  root: {
    background: "#dff5e4",
    textAlign: "center",
  },
  drawer: {
    textAlign: "center",
  },
  drawerPaper: {
    width: "16.67%",
    textAlign: "center",
  },
  logo: {
    width: 125,
    height: 45,
    flexGrow: 0,
    padding: "0 0 1.4px",
  },
  logoBox: {
    display: "flex",
    margin: "10px",
    marginTop: "30px",
    marginRight: "auto",
    marginLeft: "10%",
  },
  mobileLogoBox: {
    display: "flex",
    justifyContent: "center",
  },
  logoIn: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 43.4,
  },
  logoText: {
    textAlign: "center",
    width: "200%",
    marginTop: "60px",
    marginBottom: "60px",
    fontSize: 18,
  },
  signInButton: {
    width: 311,
    height: 38,
    flexGrow: 0,
    padding: "10px 50px 10px 70px !important",
    marginTop: 24,
    borderRadius: "5px !important",
    boxShadow:
      " 0 2px 3px 0 rgba(0, 0, 0, 0.17), 0 0 3px 0 rgba(0, 0, 0, 0.08) !important",
    backgroundColor: "#FFF",
    "& div": {
      padding: "4px !important",
    },
    "& span": {
      padding: "0px !important",
      fontWeight: "800 !important",
      fontSize: "15px !important",
    },
  },
  form: {
    marginTop: 40,
  },
  mobileView: {
    width: 392,
    height: 851,
    flexGrow: 0,
    padding: "64px 40px 305px",
  },
  mobileViewTextBox: {
    marginTop: 8,
    color: "#b8b8b8",
    height: 48,
  },
  registerSection: {
    display: "flex",
    flexDirection: "column",
    fontSize: 17,
    fontWeight: 600,
    marginTop: 40,
    textAlign: "center",
    "& a": {
      color: "#48b7a7 !important",
      fontWeight: "bold",
      marginLeft: 6,
    },
  },
  logoTextBox: {
    display: "flex",
    justifyContent: "center",
  },
  optionText: {
    fontSize: 15,
    marginTop: "30px",
    marginLeft: "30px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    "@media(max-width: 768px)": {
      textAlign: "center",
    },
  },
  referLink: {
    fontSize: 15,
    marginTop: "30px",
    marginLeft: "3px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
  },
  forgotPassword: {
    fontSize: 15,
    margin: "2px 0px 6px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    "@media(max-width: 768px)": {
      textAlign: "center",
    },
  },
}));

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [busiName, setBusiName] = useState("");
  const [busiLink, setBusiLink] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("");

  const [termsAcceptedBuy, setTermsAcceptedBuy] = useState(false);
  const [termsAcceptedSell, setTermsAcceptedSell] = useState(false);

  const [regIndex, setRegIndex] = useState(0);

  const [confirmpassword, setConfirmPassword] = useState("");

  const [passwordMsg] = useState("");
  const [passwordIssue] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState(false);
  const [confirmPasswordInfo, setConfirmPasswordInfo] = useState(false);

  const [reqSat1, setReqSat1] = useState("invalid");
  const [reqSat2, setReqSat2] = useState("invalid");
  const [reqSat3, setReqSat3] = useState("invalid");
  const [reqSat4, setReqSat4] = useState("invalid");
  const [reqSat5, setReqSat5] = useState("invalid");
  const [register]: any = usePostRegisterMutation();
  const [googlelogin]: any = usePostGoogleLoginMutation();
  let regexp = new RegExp("(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}");

  let regexp1 = new RegExp("(?=.*[a-z])");
  let regexp2 = new RegExp("(?=.*[A-Z])");
  let regexp3 = new RegExp("(?=.*[0-9])");

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  function checkPass(password: string) {
    if (regexp1.test(password)) {
      setReqSat1("valid");
    } else {
      setReqSat1("invalid");
    }
    if (regexp2.test(password)) {
      setReqSat2("valid");
    } else {
      setReqSat2("invalid");
    }
    if (regexp3.test(password)) {
      setReqSat3("valid");
    } else {
      setReqSat3("invalid");
    }
    if (password.length >= 6) {
      setReqSat4("valid");
    } else {
      setReqSat4("invalid");
    }
  }

  function checkConfirmPass(confirmpassword: string) {
    if (password === confirmpassword) {
      setReqSat5("valid");
    } else {
      setReqSat5("invalid");
    }
  }

  function validateForm() {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length >= 6 &&
      password === confirmpassword &&
      regexp.test(password) &&
      termsAcceptedBuy
    );
  }

  function validateP1() {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0;
  }

  function validateP2() {
    return busiName.length > 0 && busiLink.length > 0 && type !== "";
  }

  const handleSubmit: any = async (e: any) => {
    e.preventDefault();
    const regExpEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (regExpEmail.test(email)) {
      try {
        await register({
          firstName,
          lastName,
          email,
          password,
          company: busiName,
          link: busiLink,
        });

        navigate(homeRoute);
      } catch (err) {
        toast.error("Email is already exist");
      }
    } else {
      toast.error("Please type correct email");
    }
  };

  const responseGoogle = async (response: any) => {
    const creds = response.credential;
    if (creds) {
      try {
        const userInfo: any = jwt_decode(creds);
        const payload: any = {
          email: userInfo.email,
          googleId: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          log_token: crypto
            .createHash("sha256")
            .update(
              GOOGLE_LOGIN_HASH_KEY +
                userInfo.sub +
                userInfo.email +
                userInfo.given_name
            )
            .digest("hex"),
        };
        const {
          data: { token },
        } = await googlelogin(payload);
        localStorage.setItem("token", token);

        navigate(homeRoute);
      } catch (err) {}
    }
  };

  return (
    <>
      <div style={{ height: "100vh" }}>
        <div style={{ height: "100vh" }}>
          <Grid
            container
            spacing={0}
            sx={{ height: "100%" }}
            className="Login-form"
          >
            <Grid item xs={0} sm={6} md={7} lg={7} className="Loginform">
              <LeftPanel>
                <div className={classes.logoBox}>
                  <a href="https://www.sotira.co/">
                    <img alt="logo" className={classes.logo} src={larlogo} />
                  </a>
                </div>
                <div>
                  <div className={classes.logoTextBox}></div>
                  <CarouselBox className="Login">
                    <Carousel
                      pause="hover"
                      className="carousel-content"
                      bsPrefix="carousel"
                      indicators={false}
                      fade={true}
                      nextLabel={null}
                      prevLabel={null}
                      slide={false}
                      activeIndex={index}
                      onSelect={handleSelect}
                    >
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img1} alt="First slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <strong>
                              <h5>
                                Discover how much cash you can unlock for your
                                excess inventory
                              </h5>
                            </strong>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img2} alt="Second slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Connect with a vetted network of private buyers
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img3} alt="Third slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Convert dead inventory to working capital within a
                              week
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="img-wrapper">
                          <img src={img4} alt="Fourth slide" />
                        </div>
                        <Carousel.Caption>
                          <div className="caption-box">
                            <h5>
                              Assess and compare profitable and efficient
                              offloading options
                            </h5>
                          </div>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </CarouselBox>
                </div>
              </LeftPanel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}
              className="login-right-wrapper"
            >
              {/* this is the side Login */}

              <RightPanel>
                <div className="mobileLogoBox">
                  <img alt="logo" className={classes.logo} src={larlogo} />
                </div>
                <LoginForm>
                  <Form className="signinform">
                    <NavbarBox>
                      <NavBar />
                    </NavbarBox>
                    <h2 className="titleLog"> Sign Up </h2>
                    <Form.Group className="form-field">
                      <GoogleLogin
                        size="large"
                        width="170"
                        onSuccess={responseGoogle}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </Form.Group>
                    {regIndex === 0 && (
                      <div>
                        <Form.Group className="form-field">
                          <Form.Label className="title">First Name</Form.Label>
                          <Form.Control
                            autoFocus
                            type="firstName"
                            value={firstName}
                            placeholder="Enter first name"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="form-field">
                          <Form.Label className="title">Last Name</Form.Label>
                          <Form.Control
                            type="lastName"
                            value={lastName}
                            placeholder="Enter last name"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="form-field">
                          <Form.Label className="title">Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={email}
                            placeholder="Enter email address"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Group>

                        <Button
                          disabled={!validateP1()}
                          onClick={() => setRegIndex(1)}
                        >
                          Next
                        </Button>
                      </div>
                    )}
                    {regIndex === 1 && (
                      <div>
                        <Form.Group className="form-field">
                          <Form.Label className="title">
                            Business Name
                          </Form.Label>
                          <Form.Control
                            autoFocus
                            type="businessName"
                            value={busiName}
                            placeholder="Enter business name"
                            onChange={(e) => setBusiName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group className="form-field">
                          <Form.Label className="title">
                            Business Website
                          </Form.Label>
                          <Form.Control
                            type="businessWebsite"
                            value={busiLink}
                            placeholder="Enter website name"
                            onChange={(e) => setBusiLink(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="form-field">
                          <Form.Label className="title">Business Type</Form.Label>
                          <Form.Control
                            as="select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className="busi-select"
                          >
                            <option value="">Select Business Type</option>
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                            <option value="buyer-seller">Buyer and Seller</option>
                          </Form.Control>
                        </Form.Group>

                        <Button
                          className="login-button"
                          onClick={() => setRegIndex(0)}
                        >
                          Back
                        </Button>

                        <Button
                          disabled={!validateP2()}
                          onClick={() => setRegIndex(2)}
                        >
                          Next
                        </Button>
                      </div>
                    )}
                    {regIndex === 2 && (
                      <div>
                        <Form.Group className="form-field">
                          <Form.Label className="title">Password</Form.Label>

                          <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              checkPass(e.target.value);
                            }}
                            onClick={() => setPasswordInfo(true)}
                          />
                          {passwordInfo && (
                            <div>
                              <ul className="passwordReq">
                                <li className={reqSat1}> Lowercase letter </li>
                                <li className={reqSat2}>
                                  {" "}
                                  Capital (Uppercase) letter{" "}
                                </li>
                                <li className={reqSat3}> Number </li>
                                <li className={reqSat4}>
                                  {" "}
                                  Minimum 6 characters{" "}
                                </li>
                              </ul>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="form-field">
                          <div className="d-flex justify-content-between">
                            <Form.Label className="title">
                              Confirm Password
                            </Form.Label>
                            {confirmPasswordInfo && (
                              <div>
                                <ul className="passwordReq">
                                  <li
                                    className={reqSat5}
                                    id="confirm-password-error"
                                  ></li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <Form.Control
                            type="password"
                            value={confirmpassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              checkConfirmPass(e.target.value);
                            }}
                            onClick={() => setConfirmPasswordInfo(true)}
                          />
                        </Form.Group>
                        {passwordIssue && (
                          <div className="passworderror">
                            <p>{passwordMsg}</p>
                          </div>
                        )}
                        {/* <div className={classes.referLink}>
                          <a
                            style={{ color: "#89D0C6", textDecoration: "none" }}
                            href={RegisterRoute}
                          >
                            Refer a friend
                          </a>
                        </div> */}

                        <Form.Group className="form-field">
                          <Form.Check
                            type="checkbox"
                            label={
                              <>
                                I have read and accept the <a href="https://www.sotira.co/terms-of-service">terms of service</a>
                              </>
                            }
                            checked={termsAcceptedBuy}
                            onChange={(e) => setTermsAcceptedBuy(e.target.checked)}
                          />
                        </Form.Group>
                        <Button
                          className="login-button"
                          onClick={() => setRegIndex(1)}
                        >
                          Back
                        </Button>

                        <Button
                          className="login-button"
                          type="submit"
                          disabled={!validateForm()}
                          onClick={handleSubmit}
                        >
                          Sign Up
                        </Button>
                      </div>
                    )}

                    <div className={classes.optionText}>
                      Already have an account?
                      <a
                        style={{ color: "#89D0C6", textDecoration: "none" }}
                        href={homeRoute}
                      >
                        &nbsp;Sign in here
                      </a>
                    </div>
                  </Form>
                </LoginForm>
              </RightPanel>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Register;
