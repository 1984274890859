import React, { useRef, useState } from "react";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import aws from "aws-sdk";
import * as XLSX from "xlsx";
import axios from "axios";
import { confirmationRoutes } from "../../Routes/constants";
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import GetUser from "../../Utils/Get-user";
import { toast } from "react-toastify";

import { usePostAddExcessFile, useGetExcessId } from "../../Redux/api/User";

import Papa, { ParseResult } from "papaparse";


import {
  FileUploadButton,
  FileUploadHeader,
  FileUploadBox,
  AlertBox,
  UploadedFile,
} from "./styles";
import "../../assets/styling/style.css";

const bucketName = "sotira-excess-files-test";
const region = "us-west-1";
const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
const secretAccessKey = "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";

export default function UPopup() {
  const userID = GetUser();
  const storageTypes = ['WMS', 'TMS', 'Storage Capacity Model', 'Google Sheets'];
  const [storage, setStorage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [upload, setUpload] = useState(false);
  const [listingData, setListingData] = useState<String[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [AddExcessFile]: any = usePostAddExcessFile();
  const { data: { data: { excess = [] } = {} } = {} } = useGetExcessId(userID);
  const [lot, setLot] = useState(true);
  const [raw, setRaw] = useState(false);

  const [sendEmails, setSendEmails] = useState(false);

  //email List Items
  const [emailFile, setEmailFile] = useState<File | null>(null);
  const [emailData, setEmailData] = useState<String[]>([]);
  const emailFileInputRef = useRef<HTMLInputElement>(null);

  const date = new Date();

  //Get Month/Year for updated Calendy Link
  const year = String(date.getFullYear());
  var month = String(date.getMonth() + 1);
  if ((date.getMonth() + 1) < 10) {
    month = "0" + String(month);
  }

  const handleEmailFileinput = () => {
    if (emailFileInputRef.current) {
      emailFileInputRef.current.click();
    }
  };
  const handleEmailFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        setEmailFile(uploadedFile);
        getEmailFileDataCSV(uploadedFile, (data: string[]) => {
          console.log("CSV Email Data:", data);
          if (data.length > 0 && upload) {
            console.log("can upload");
            setEmailData(data);
            setUpload(true);
          } else if (data.length === 0) {
            toast.error("No valid email data was found - contact support", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          } else {
            toast.error("No inventory data found - contact support", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          }
        });
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        setEmailFile(uploadedFile);
        getEmailFileDataExcel(uploadedFile, (data: string[]) => {
          console.log("Excel Email Data:", data);
          if (data.length > 0 && upload) {
            console.log("can upload");
            setEmailData(data);
            setUpload(true);
          } else if (data.length === 0) {
            toast.error("No valid email data was found - contact support", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          } else {
            toast.error("No inventory data found - contact support", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          }
        });
      } else {
        console.error("Unsupported file format.");
      }
    }
  };

  const handleCheckboxChange = (e: any) => {
		if (raw) {
      setLot(true);
      toast.error("Currently we only send full lot offerings for raw data", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      setLot(e.target.checked);
    }

	};

  const handleCheckboxChangeRaw = (e: any) => {
		setRaw(e.target.checked);
    setLot(e.target.checked);
	};

  const handleCheckboxChangeEmail = (e: any) => {
		setSendEmails(e.target.checked);
	};


  const latestDateObject = excess.find((excessdata: any) => {
    return !excessdata?.fileName;
  });

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        setFile(uploadedFile);
        getFileDataCSV(uploadedFile, (data: string[]) => {
          console.log("CSV Data:", data);
          if (data.length > 0) {
            console.log("can upload");
            setListingData(data);
            setUpload(true);
          }
        });
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(uploadedFile);
        getFileDataExcel(uploadedFile, (data: string[]) => {
          console.log("Excel Data:", data);
          if (data.length > 0) {
            console.log("can upload");
            setListingData(data);
            setUpload(true);
          } else {
            console.log("no valid data");
            toast.error("No valid data was found - contact support", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          }
        });
      } else {
        toast.error("Unsupported file format.", {
          position: "top-right",
          autoClose: 1500,
        });
      }
    }
  };

  const handleFileinput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const getFileDataCSV = (file: File, callback: (data: any[]) => void) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (typeof data === "string") {
        // Parse CSV data using react-papaparse
        Papa.parse(data, {
          header: false, // Disable header parsing initially
          complete: (parsedData: ParseResult<string[]>) => {
            if (parsedData.data.length > 0) {
              const requiredHeaders = {
                "upc": ["item upc", "upc"],
                "costPrice": ["cost", "mfsrp", "price", "sell price"],
                //"location": ["zip code"],
                "expDate": ["expiration", "expdate"],
                "quantity": ["quantity", "qty", "avail", "available"],
              };

              const optionalHeaders = {
                "location": ["zip code", "location"],
                "itemName": ["product name", "name"],
              };

              let headerRow: string[] = [];
              let requiredHeaderMappings: { [key: string]: number } = {};
              let optionalHeaderMappings: { [key: string]: number } = {};
              let headerMappings: { [key: string]: number } = {}; // Store mappings between headers and their corresponding indexes
              let numHeaderRows = 0;
    
              for (let i = 0; i < Math.min(3, parsedData.data.length); i++) {
                const row: unknown[] = parsedData.data[i] as unknown[];
                if (Array.isArray(row)) {
                  const lowercaseRow = row.map(item => (typeof item === 'string' ? item.toLowerCase() : item));
    
                  Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                    const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                    if (columnIndex !== -1 && !(headerKey in requiredHeaderMappings)) { // Only store the first occurrence of each header
                      requiredHeaderMappings[headerKey] = columnIndex;
                      if(i != 1) {
                        numHeaderRows++;
                        console.log(numHeaderRows);
                      }
                      if(i === 1) {
                        headerRow.push(headerKey); // Collect the header row based on the first row containing headers
                      }
                    }
                  });

                  Object.entries(optionalHeaders).forEach(([headerKey, possibleHeaders]) => {
                    const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                    if (columnIndex !== -1 && !(headerKey in optionalHeaderMappings)) {
                      optionalHeaderMappings[headerKey] = columnIndex;
                      if (i != 1) {
                        numHeaderRows++;
                      }
                    }
                  });

                }
              }
    
              if (Object.keys(requiredHeaderMappings).length === Object.keys(requiredHeaders).length) { // Check if all required headers were found
                let dataRows: any[] = [];
                for (let i = 1; i < parsedData.data.length; i++) {
                  if(i < numHeaderRows && i < (parsedData.data.length - 1)) {
                    console.log('skip ' + String(i) + ' row');
                    continue;
                  } else {
                    const row: unknown[] = parsedData.data[i] as unknown[];
                    if (Array.isArray(row)) {
                      let skipRow = false;
                      const rowData: any = {};
                      Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                        rowData[headerKey] = row[requiredHeaderMappings[headerKey]];
                        if (rowData[headerKey] === undefined) {
                          skipRow = true;
                        }
                      });
                      Object.entries(optionalHeaders).forEach(([headerKey]) => {
                        if (optionalHeaderMappings[headerKey] !== undefined) {
                          rowData[headerKey] = row[optionalHeaderMappings[headerKey]];
                        }
                      });
                      if(!skipRow) {
                        dataRows.push(rowData);
                      }
                    }
                  }
                }
                callback(dataRows);
              } else {
                toast.error("Required headers not found in the CSV file.", {
                  position: "top-right",
                  autoClose: 1500,
                });
                setUpload(false);
              }
            } else {
              toast.error("CSV file is empty.", {
                position: "top-right",
                autoClose: 1500,
              });
              setUpload(false);
            }
          },
          error: (error: any) => {
            console.error("Error parsing CSV:", error);
            setUpload(false);
          },
        });
      } else {
        toast.error("Failed to read CSV data.", {
          position: "top-right",
          autoClose: 1500,
        });
        setUpload(false);
      }
    };
    reader.readAsText(file);
  };
  
  
  const getFileDataExcel = (file: File, callback: (data: any[]) => void) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (data instanceof ArrayBuffer) {
        const workbook = XLSX.read(data, { type: "array" });
        if (workbook.SheetNames.length > 0) {
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const requiredHeaders = {
            "upc": ["item upc", "upc"],
            "costPrice": ["cost", "mfsrp", "msrp", "price", "sell price"],
            //"location": ["zip code"],
            "expDate": ["expiration", "expdate"],
            "quantity": ["quantity", "qty", "avail", "available"],
          };

          const optionalHeaders = {
            "location": ["zip code", "location"],
            "itemName": ["product name", "name"],
          };

          let headerRow: string[] = [];
          let requiredHeaderMappings: { [key: string]: number } = {};
          let optionalHeaderMappings: { [key: string]: number } = {};
          let headerMappings: { [key: string]: number } = {}; // Store mappings between headers and their corresponding indexes
          let numHeaderRows = 0;
  
          for (let i = 0; i < Math.min(3, excelData.length); i++) {
            const row: unknown[] = excelData[i] as unknown[];
            if (Array.isArray(row)) {
              const lowercaseRow = row.map(item => (typeof item === 'string' ? item.toLowerCase() : item));
  
              Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                if (columnIndex !== -1 && !(headerKey in requiredHeaderMappings)) { // Only store the first occurrence of each header
                  requiredHeaderMappings[headerKey] = columnIndex;
                  console.log(i);
                  if(i != 0) {
                    numHeaderRows++;
                  }
                  if(i === 1) {
                    headerRow.push(headerKey); // Collect the header row based on the first row containing headers
                  }
                }
              });

              Object.entries(optionalHeaders).forEach(([headerKey, possibleHeaders]) => {
                const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                if (columnIndex !== -1 && !(headerKey in optionalHeaderMappings)) {
                  optionalHeaderMappings[headerKey] = columnIndex;
                  if (i != 0) {
                    numHeaderRows++;
                  }
                }
              });
            }
          }
  
          if (Object.keys(requiredHeaderMappings).length === Object.keys(requiredHeaders).length) { // Check if all required headers were found
            let dataRows: any[] = [];
            for (let i = 1; i < excelData.length; i++) {
              if(i < numHeaderRows && i < (excelData.length - 1)) {
                console.log('skip ' + String(i) + ' row');
                continue;
              } else {
                const row: unknown[] = excelData[i] as unknown[];
                if (Array.isArray(row)) {
                  let skipRow = false;
                  const rowData: any = {};
                  Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                    rowData[headerKey] = row[requiredHeaderMappings[headerKey]];
                    
                    if (headerKey === "expDate" && row[requiredHeaderMappings[headerKey]]) {
                      // Convert "expiration" data to Date object
                      console.log('date');
                      console.log(row[requiredHeaderMappings[headerKey]]);
                      const numericDate = row[requiredHeaderMappings[headerKey]] as number;
                      rowData[headerKey] = new Date((numericDate - (25567 + 1)) * 86400 * 1000);
                    } else {
                        rowData[headerKey] = row[requiredHeaderMappings[headerKey]];
                    }
                    if (rowData[headerKey] === undefined) {
                        skipRow = true;
                    }
                  });
                  Object.entries(optionalHeaders).forEach(([headerKey]) => {
                    if (optionalHeaderMappings[headerKey] !== undefined) {
                      rowData[headerKey] = row[optionalHeaderMappings[headerKey]];
                      if (headerKey === "expDate" && row[optionalHeaderMappings[headerKey]]) {
                        // Convert "expiration" data to Date object
                        console.log('date');
                        console.log(row[optionalHeaderMappings[headerKey]]);
                        const numericDate = row[optionalHeaderMappings[headerKey]] as number;
                        rowData[headerKey] = new Date((numericDate - (25567 + 1)) * 86400 * 1000);
                      } else {
                          rowData[headerKey] = row[optionalHeaderMappings[headerKey]];
                      }
                    }
                  });
                  if(!skipRow) {
                    dataRows.push(rowData);
                  }
                }
              }
            }
            callback(dataRows);
          } else {
            toast.error("Required headers not found in the first or second rows of Excel file.", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          }
        } else {
          toast.error("No sheets found in Excel file.", {
            position: "top-right",
            autoClose: 1500,
          });
          setUpload(false);
        }
      } else {
        toast.error("Failed to read Excel data.", {
          position: "top-right",
          autoClose: 1500,
        });
        setUpload(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };


  const getEmailFileDataCSV = (file: File, callback: (data: any[]) => void) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (typeof data === "string") {
        // Parse CSV data using react-papaparse
        Papa.parse(data, {
          header: false, // Disable header parsing initially
          complete: (parsedData: ParseResult<string[]>) => {
            if (parsedData.data.length > 0) {
              const requiredHeaders = {
                "emails": ["email"],
              };
              let headerRow: string[] = [];
              let headerMappings: { [key: string]: number } = {}; // Store mappings between headers and their corresponding indexes
              let numHeaderRows = 0;
    
              for (let i = 0; i < Math.min(3, parsedData.data.length); i++) {
                const row: unknown[] = parsedData.data[i] as unknown[];
                if (Array.isArray(row)) {
                  const lowercaseRow = row.map(item => (typeof item === 'string' ? item.toLowerCase() : item));
    
                  Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                    const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                    if (columnIndex !== -1 && !(headerKey in headerMappings)) { // Only store the first occurrence of each header
                      headerMappings[headerKey] = columnIndex;
                      if(i != 1) {
                        numHeaderRows++;
                        console.log(numHeaderRows);
                      }
                      if(i === 1) {
                        headerRow.push(headerKey); // Collect the header row based on the first row containing headers
                      }
                    }
                  });
                }
              }
    
              if (Object.keys(headerMappings).length === Object.keys(requiredHeaders).length) { // Check if all required headers were found
                let dataRows: any[] = [];
                for (let i = 1; i < parsedData.data.length; i++) {
                  if(i < numHeaderRows && i < (parsedData.data.length - 1)) {
                    console.log('skip ' + String(i) + ' row');
                    continue;
                  } else {
                    const row: unknown[] = parsedData.data[i] as unknown[];
                    if (Array.isArray(row)) {
                      let skipRow = false;
                      const rowData: any = {};
                      Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                        rowData[headerKey] = row[headerMappings[headerKey]];
                        if (rowData[headerKey] === undefined) {
                          skipRow = true;
                        }
                      });
                      if(!skipRow) {
                        dataRows.push(rowData);
                      }
                    }
                  }
                }
                callback(dataRows);
              } else {
                toast.error("Required headers not found in the CSV file.", {
                  position: "top-right",
                  autoClose: 1500,
                });
                setUpload(false);
              }
            } else {
              toast.error("CSV file is empty.", {
                position: "top-right",
                autoClose: 1500,
              });
              setUpload(false);
            }
          },
          error: (error: any) => {
            console.error("Error parsing CSV:", error);
            setUpload(false);
          },
        });
      } else {
        toast.error("Failed to read CSV data.", {
          position: "top-right",
          autoClose: 1500,
        });
        setUpload(false);
      }
    };
    reader.readAsText(file);
  };
  
  
  const getEmailFileDataExcel = (file: File, callback: (data: any[]) => void) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result;
      if (data instanceof ArrayBuffer) {
        const workbook = XLSX.read(data, { type: "array" });
        if (workbook.SheetNames.length > 0) {
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          console.log(excelData);
          const requiredHeaders = {
            "emails": ["email"],
          };
          let headerRow: string[] = [];
          let headerMappings: { [key: string]: number } = {}; // Store mappings between headers and their corresponding indexes
          let numHeaderRows = 0;
  
          for (let i = 0; i < Math.min(3, excelData.length); i++) {
            const row: unknown[] = excelData[i] as unknown[];
            if (Array.isArray(row)) {
              const lowercaseRow = row.map(item => (typeof item === 'string' ? item.toLowerCase() : item));
  
              Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                const columnIndex = lowercaseRow.findIndex(item => typeof item === 'string' && possibleHeaders.some(possibleHeader => item.toLowerCase().includes(possibleHeader.toLowerCase())));
                if (columnIndex !== -1 && !(headerKey in headerMappings)) { // Only store the first occurrence of each header
                  headerMappings[headerKey] = columnIndex;
                  if(i != 1) {
                    console.log(lowercaseRow);
                    numHeaderRows++;
                    console.log(numHeaderRows);
                  }
                  if(i === 1) {
                    headerRow.push(headerKey); // Collect the header row based on the first row containing headers
                  }
                }
              });
            }
          }
  
          if (Object.keys(headerMappings).length === Object.keys(requiredHeaders).length) { // Check if all required headers were found
            let dataRows: any[] = [];
            for (let i = 1; i < excelData.length; i++) {
              if(i < numHeaderRows && i < (excelData.length - 1)) {
                console.log('skip ' + String(i) + ' row');
                continue;
              } else {
                const row: unknown[] = excelData[i] as unknown[];
                if (Array.isArray(row)) {
                  let skipRow = false;
                  const rowData: any = {};
                  Object.entries(requiredHeaders).forEach(([headerKey, possibleHeaders]) => {
                    rowData[headerKey] = row[headerMappings[headerKey]];
                    
                    if (headerKey === "expDate" && row[headerMappings[headerKey]]) {
                      // Convert "expiration" data to Date object
                      console.log('date');
                      console.log(row[headerMappings[headerKey]]);
                      const numericDate = row[headerMappings[headerKey]] as number;
                      rowData[headerKey] = new Date((numericDate - (25567 + 1)) * 86400 * 1000);
                    } else {
                        rowData[headerKey] = row[headerMappings[headerKey]];
                    }
                    if (rowData[headerKey] === undefined) {
                        skipRow = true;
                    }
                  });
                  if(!skipRow) {
                    dataRows.push(rowData);
                  }
                }
              }
            }
            callback(dataRows);
          } else {
            toast.error("Required headers not found in the first or second rows of Excel file.", {
              position: "top-right",
              autoClose: 1500,
            });
            setUpload(false);
          }
        } else {
          toast.error("No sheets found in Excel file.", {
            position: "top-right",
            autoClose: 1500,
          });
          setUpload(false);
        }
      } else {
        toast.error("Failed to read Excel data.", {
          position: "top-right",
          autoClose: 1500,
        });
        setUpload(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  
  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const fileKey = userID + "/" + "inventory" + "/" + String(date) + "/"+ String(file.name);

    const params = {
      Key: fileKey,
      Bucket: bucketName,
      Body: file,
      ContentType: file.type,
    };

     try {
       await s3.putObject(params).promise();
       const payload = {
         userID,
         excessID: latestDateObject?._id,
         fileName: file.name,
       };

       await AddExcessFile(payload);
     } catch (error: any) {
       console.error("Error uploading file:", error);
     }

    var params_listing = {
      userID: userID,
      listingData: listingData,
      emails: emailData,
      lot: lot,
      emailSend: sendEmails,
      fileId: fileKey,
      sendRaw: raw,
    };

    if(userID === "6580ef3206fae9000235b04a" && !sendEmails) {
      params_listing = {
        userID: userID,
        listingData: listingData,
        emails: ['garykk1234@gmail.com'],
        lot: lot,
        emailSend: true,
        fileId: fileKey,
        sendRaw: raw,
      };
    }

    if(userID === "65f8ae42a27e970002f514bb" && !sendEmails) {
      params_listing = {
        userID: userID,
        listingData: listingData,
        emails: ['a.bhasin@northeastern.edu'],
        lot: lot,
        emailSend: true,
        fileId: fileKey,
        sendRaw: raw,
      };
    }

    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'listing/add_multi_listing', params_listing).then((response) => {
        console.log(response);
        if(sendEmails) {
          toast.success("Emails have been sent", {
            position: "top-right",
            autoClose: 1500,
          });
        } else {
          toast.success("Inventory Uploaded", {
            position: "top-right",
            autoClose: 1500,
          });
        }
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    } catch (error) {
      console.error("Error Submitting Order:", error);
    }

  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    var type: any = value;
    setStorage(type);
  };

  return (
    <>
      <div>
        {" "}
        <FileUploadHeader>Connect Storage System</FileUploadHeader>
        
        <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
        <InputLabel id="multiple-date-label">Select System Type</InputLabel>
        <Select
          labelId="multiple-date-label"
          id="multiple-date"
          value={storage}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
        >
          {storageTypes.map((type) => (
            <MenuItem
              key={type}
              value={type}
            >
              {type}
            </MenuItem>
          ))}
        </Select>
        <br/>

        {storage !== '' &&
        <FileUploadBox onClick={handleFileinput}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            disabled={file !== null}
          />
          {!file ? (
            <p style={{ padding: "20px", marginBottom: "0px" }}>
              Connect to your {storage} provider
            </p>
          ) : (
            <>
              <UploadedFile>{file.name}</UploadedFile>
            </>
          )}
        </FileUploadBox>
        }

        {sendEmails && 
          <div>
            <FileUploadHeader>Upload Emails</FileUploadHeader>
            <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
            <FileUploadBox onClick={handleEmailFileinput}>
              <input
                type="file"
                ref={emailFileInputRef}
                style={{ display: "none" }}
                onChange={handleEmailFileChange}
                disabled={emailFile !== null}
              />
              {!emailFile ? (
                <p style={{ padding: "20px", marginBottom: "0px" }}>
                  Drop file here or click to upload
                </p>
              ) : (
                <>
                  <UploadedFile>{emailFile.name}</UploadedFile>
                </>
              )}
            </FileUploadBox>
          </div>
      }
        <AlertBox>
          <Alert severity="error" className="alert-text">
            Trouble connecting your inventory data? Email {" "}
            <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
            <a href={"https://calendly.com/amritabhasin/15min?back=1&month=" + year + "-" + month} target="_blank">
              schedule a meeting at this link
            </a>{" "}
            and we will connect it for you.
          </Alert>
        </AlertBox>
      </div>

      {file && !sendEmails &&
        <div>
          <FileUploadButton onClick={handleUpload} disabled={!upload}>
            Upload Inventory
          </FileUploadButton>
        </div>
      }

      {file && emailFile && (
        <div>
        <FileUploadButton onClick={handleUpload} disabled={!upload}>
          Send And Process Listings
        </FileUploadButton>

        <label htmlFor="checkbox">Send as a lot (Buy all option only): </label> {" "}
            
        <input
          type="checkbox"
          id="checkbox"
          checked={lot}
          onChange={handleCheckboxChange}
        />
        
        <br />

        <label htmlFor="checkbox">Send entire sheet (raw data) </label> {" "}
                    
        <input
          type="checkbox"
          id="checkbox"
          checked={raw}
          onChange={handleCheckboxChangeRaw}
        />

        </div>
      )} 
    </>
  );
}
