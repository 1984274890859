import { useCallback } from "react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";

import { logoutUserSuccess } from "../login/reducer";

import { tagTypes } from "../tagTypes";

const generateEndPoint = (endpoint: any, query?: any) => {
  return `${endpoint}${
    query
      ? `?${Object.entries(query)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
      : ""
  }`;
};

const displayErrorMessage = (error: any) => {
  const statusCode = error?.originalStatus || error?.data?.StatusCode || 0;
  if (Math.floor(statusCode / 100) === 2) return;
  let errorMessage;
  switch (error?.data?.StatusCode) {
    case 400:
      errorMessage = error?.data?.Error || "Invalid data";
      break;
    case 401:
      errorMessage = error?.data?.Error || "Unauthenticated";
      break;
    case 403:
      errorMessage = error?.data?.Error || "No access for given URL";
      break;
    case 404:
      errorMessage = error?.data?.Error || "Page not found";
      break;
    case 500:
      errorMessage = error?.data?.Error || "Internal server error";
      break;
    default:
    // errorMessage =
    //   error?.data?.Error || "Somthing went wrong, Please try again";
  }
  toast.error(errorMessage);
};

const transformResponse = (response: any) => {
  return {
    data: response,
  };
};

const onQueryStarted: any = async (
  { toastMessage, errorMessage }: { toastMessage: any; errorMessage: any },
  { queryFulfilled, dispatch }: { queryFulfilled: any; dispatch: any }
) => {
  try {
    const res = await queryFulfilled;
    if (toastMessage || res?.data?.Message) {
      toast.success(toastMessage || res?.data?.Message);
    }
  } catch (error: any) {
    if (errorMessage) {
      toast.error(errorMessage);
    } else {
      displayErrorMessage(error?.error);
    }
    if (error?.error?.status === 401) {
      dispatch(logoutUserSuccess());
      dispatch(api.util.resetApiState());
    }
  }
};

export const api = createApi({
  reducerPath: "api",
  tagTypes,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  }),
  endpoints: (builder) => ({
    get: builder.query({
      query: ({ endpoint, query = null }: { endpoint: string; query: any }) => {
        if (query) {
          const updatedQuery = Object.entries(query).reduce(
            (res, [key, value]) =>
              value === "" ? res : { ...res, [key]: value },
            {}
          );
          return generateEndPoint(endpoint, updatedQuery);
        }
        return generateEndPoint(endpoint);
      },
      providesTags: (_, __, val: any) => val?.tags || [],
      transformResponse,
      onQueryStarted,
    }),
    post: builder.mutation({
      query: ({
        endpoint,
        payload,
        query = null,
      }: {
        endpoint: any;
        payload: any;
        query: any;
      }) => {
        const postEndpoint = generateEndPoint(endpoint, query);
        return {
          url: postEndpoint,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (_, __, val: any) => val?.tags || [],
      transformResponse,
      onQueryStarted,
    }),
    put: builder.mutation({
      query: ({
        endpoint,
        payload,
        query = null,
      }: {
        endpoint: any;
        payload: any;
        query: any;
      }) => {
        const putEndpoint = generateEndPoint(endpoint, query);
        return {
          url: putEndpoint,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (_, __, val: any) => val?.tags || [],
      transformResponse,
      onQueryStarted,
    }),
    delete: builder.mutation({
      query: ({
        endpoint,
        query = null,
      }: {
        endpoint: any;
        payload: any;
        query: any;
      }) => {
        const deleteEndpoint = generateEndPoint(endpoint, query);
        return {
          url: deleteEndpoint,
          method: "DELETE",
        };
      },
      invalidatesTags: (_, __, val: any) => val?.tags || [],
      onQueryStarted,
    }),
  }),
});

export const {
  useGetQuery,
  useLazyGetQuery,
  usePostMutation,
  usePutMutation,
  useDeleteMutation,
} = api;

export const useLazyCachedGetQuery = (...args: any) => {
  const [get, metadata] = useLazyGetQuery(...args);
  const cachedGet = useCallback(
    (...args: any) => {
      // @ts-ignore
      return get(...args, true);
    },
    [get]
  );

  return [cachedGet, metadata];
};
