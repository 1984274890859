import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from '@mui/material/CircularProgress';

import {
  BoldText,
  ProgressDiv,
} from "./styles";

const createData = (
  productId: string,
  size: string,
  unit: number,
  date: string
) => {
  return { productId, size, unit, date };
};

const previewData = [
  createData("GA-23189", "S", 48, " 07 / 03 / 2021"),
  createData("GA-23190", "M", 35, " 07 / 03 / 2021"),
  createData("GA-23191", "L", 68, " 07 / 03 / 2021"),
];

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
    marginTop: "20px",
  },
  table: {
    backgroundColor: "#fcfcfc",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
  },
  cell: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    color: "#000",
    backgroundColor: "#f3f0f0",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
  sellPrice: {
    color: "rgb(72, 183, 167) !important",
  },
  quantity: {
    color: "none",
  },
  exp: {
    color: "#ff6767 !important",
  },
  sellby: {
    color: "none",
  },
}));

interface ListingItem {
  _id: any;
  itemName: any;
  upc: any;
  sellPrice: any;
  costPrice: any;
  quantity: any;
  location: any;
  expDate: any;
  endDate: any;
  sold: any;
  status: any;
  buyer: any;
  seller: any;
}

interface DataTableProps {
  offerData: ListingItem[];
  isLoading: boolean;
}

const DataTable: React.FC<DataTableProps> = ({ offerData, isLoading })=> {
  const classes = useStyles();
  //console.log(offerData);
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(5);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ListingItem>("expDate");
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(offerData.slice(startFoundIndex, endFoundIndex));
  }, [offerData, foundPage, foundRowsPerPage]);

  const handleFoundChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleRequestSort = (property: keyof ListingItem) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  function getFormattedDate(date: any) {
    //console.log(date);
    var year = date.getFullYear();
    //console.log(year);
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  const sortedData = offerData.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });


  

  return (
    <div>
      {isLoading? <ProgressDiv> <CircularProgress /> </ProgressDiv>:
      <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.header} sortDirection={orderBy === "upc" ? order : false}>
                <TableSortLabel
                  active={orderBy === 'upc'}
                  direction={orderBy === 'upc' ? order : 'asc'}
                  onClick={handleRequestSort('upc')}
                >
                <BoldText>
                  UPC
                </BoldText>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.header}>
              <BoldText>
                Item Name
              </BoldText>
              </TableCell>
              <TableCell align="center" className={classes.header} sortDirection={orderBy === "quantity" ? order : false}>
              <TableSortLabel
                  active={orderBy === "quantity"}
                  direction={orderBy === "quantity" ? order : "asc"}
                  onClick={handleRequestSort("quantity")}
              >
                <BoldText>       
                  Quantity
                </BoldText>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.header} sortDirection={orderBy === "sellPrice" ? order : false}>
              <TableSortLabel
                  active={orderBy === "sellPrice"}
                  direction={orderBy === "sellPrice" ? order : "asc"}
                  onClick={handleRequestSort("sellPrice")}
                >
                <BoldText> 
                  Sell Price
                </BoldText>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.header} sortDirection={orderBy === "expDate" ? order : false}>
              <TableSortLabel
                  active={orderBy === "expDate"}
                  direction={orderBy === "expDate" ? order : "asc"}
                  onClick={handleRequestSort("expDate")}
                >
                <BoldText>
                  Exp Date
                </BoldText>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.header}>
                <BoldText>
                  Location (ZIP)
                </BoldText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(foundPage * foundRowsPerPage, foundPage * foundRowsPerPage + foundRowsPerPage).map((item, index) => (
              <React.Fragment key={index}>
                <TableRow key={item._id}>
                  <TableCell>{item.upc}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell className={classes.quantity}>{item.quantity}</TableCell>
                  <TableCell className={classes.sellPrice}>${item.sellPrice}</TableCell>
                  <TableCell className={classes.exp}>{item.expDate? getFormattedDate(new Date(item.expDate)) : "N/A"}</TableCell>
                  <TableCell> 
                    <Tooltip title={`Zip Code: ${item.location}`}> 
                      <span>{item.location==="undefined"? "N/A":item.location}</span> 
                    </Tooltip> 
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={offerData.length}
          rowsPerPage={foundRowsPerPage}
          page={foundPage}
          onPageChange={handleFoundChangePage}
          onRowsPerPageChange={handleFoundChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem', 
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem',
            },
          }}
        />
        </div>}
    </div>
  );
};

export default DataTable;