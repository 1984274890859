import { useState, useRef, useEffect, useMemo } from "react";
import { DialogContentText, DialogContent, MenuItem, Select } from "@mui/material";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeValidationError } from '@mui/x-date-pickers/models';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";

import {
  ConfirmationText,
  ConfirmationHeader,
  NextButton,
} from "./styles";

interface Location {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  schedule: any;
}

interface PPopupProps {
  location?: Location;
  offerDates: any[];
  offerID: string;
}

export default function PPopup({ location, offerDates, offerID }: PPopupProps) {
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedDateTime, setSelectedDateTime] = useState<dayjs.Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false);
  const [error, setError] = useState<TimeValidationError | null>(null);
  const [isTimeSelected, setIsTimeSelected] = useState<boolean>(false);
  const latestOfferDate = dayjs(offerDates[offerDates.length - 1]);
  console.log(latestOfferDate);
  const maxDate = latestOfferDate.add(7, 'day');
  console.log(maxDate);
  const [nerrorMessage, setErrorMessage] = useState<string | null>(null);
  console.log(latestOfferDate);
  console.log(maxDate);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxTime': {
        return 'Invalid Date - See Above';
      }
      case 'minTime': {
        return 'Invalid Date - See Above';
      }

      default: {
        return '';
      }
    }
  }, [error]);

  const handleDayChange = (event: any) => {
    setSelectedDay(event.target.value as string);
    setIsTimeSelected(false); 
    setSelectedTime(null);
  };

  const handleTimeChange = (newValue: Dayjs | null) => {
    if (newValue) {
        const minTime = removeDatePart(selectedSchedule.start);
        const maxTime = removeDatePart(selectedSchedule.end);
        const isValidTime = newValue.isSame(minTime) || newValue.isSame(maxTime) || (newValue.isAfter(minTime) && newValue.isBefore(maxTime));   
        setIsTimeSelected(isValidTime); 
        if (!isValidTime) {
            return; 
        }
    } else {
        setIsTimeSelected(false);
    }
    setSelectedTime(newValue);
  };

  const handleDateTimeChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      const minTime = getMinTime();
      const maxTime = getMaxTime();
      const isValidTime = newValue.isSame(minTime) || newValue.isSame(maxTime) || (newValue.isAfter(minTime) && newValue.isBefore(maxTime));   
      setIsTimeSelected(isValidTime); 
    } else {
        setIsTimeSelected(false);
    }
    setSelectedDateTime(newValue);
  };

  const removeDatePart = (timeString: string) => {
    const parsedTime = dayjs(timeString);
    return dayjs().hour(parsedTime.hour()).minute(parsedTime.minute()).second(0).millisecond(0);
  };

  const availableDays = location?.schedule?.map((schedule: any) => schedule.day) || [];

  const selectedSchedule = location?.schedule?.find(
    (schedule: any) => schedule.day === selectedDay
  );

  const handleSelectTime = async (time: Dayjs | null) => {
    if (time) {
      console.log("Selected Time:", time.format("hh:mm A"));
    }
    const params = {
      offerID: offerID,
      pickuptime: time,
    };
    await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/set_pickup', params).then((response) => {
      toast.success("Pickup time has been scheduled", {
        position: "top-right",
        autoClose: 1500,
      });
      setTimeout(() => {window.location.reload();},1000);
      
    });
  };

  const isPickupDay = (date: dayjs.Dayjs) => {
    const day = date.format('dddd');
    return availableDays.includes(day);
  };

  const formatTime = (time: string) => {
    return dayjs(time).format("hh:mm A");
  };


  const matchedDay = useMemo(() => {
    if (!selectedDateTime) return undefined;

    // Get the day of the week from the selected date
    const dayOfWeek = selectedDateTime.format("dddd"); // Returns 'Monday', 'Tuesday', etc.

    // Find the corresponding start and end times for the day
    return location?.schedule?.find((day: any) => day.day === dayOfWeek);
  }, [selectedDateTime]);

  const getMinTime = () => {
    if (!matchedDay) return undefined;

    // Get the hour and minute from the start time, apply it to the selected date
    const start = dayjs(matchedDay.start);
    return selectedDateTime?.set('hour', start.hour()).set('minute', start.minute()).set('second', 0);
  };

  const getMaxTime = () => {
    if (!matchedDay) return undefined;
    console.log(matchedDay);
    // Get the hour and minute from the end time, apply it to the selected date
    const end = dayjs(matchedDay.end);
    console.log(end);
    return selectedDateTime?.set('hour', end.hour()).set('minute', end.minute()).set('second', 0);
  };

  return (
    <div>
      {location && location.schedule && location.schedule.length > 0 ? (
        <div>
          <div>Available Pickup Days</div>
          <p>{availableDays.join(', ')}</p>

            <div>
              <DialogContentText>
                <strong>Choose a time to pickup:</strong>
                <br />
              </DialogContentText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Select date and time"
                  value={selectedDateTime}
                  onChange={handleDateTimeChange}
                  minDate={latestOfferDate}
                  maxDate={maxDate}
                  minTime={matchedDay ? getMinTime() : undefined}  // Set minimum time based on matched day
                  maxTime={matchedDay ? getMaxTime() : undefined}
                  ampm
                  shouldDisableDate={(date) => !isPickupDay(date)}
                  slotProps={{
                    textField: {
                      helperText: errorMessage,
                    },
                    popper: {
                      placement: 'right'
                    },
                  }}
                  sx={{ marginTop: "20px" }}
                />
              </LocalizationProvider>
            </div>


          {isTimeSelected &&
              <NextButton onClick={() => handleSelectTime(selectedDateTime)}>
                  Select Pickup Time
              </NextButton>
          }
        </div>
      ) : (
        <DialogContentText>No pickup schedule available for this location right now.</DialogContentText>
      )}
    </div>
  );
}