import React from "react";
import { useNavigate } from "react-router-dom";

import { questionRoute } from "../../Routes/constants";

import {
  StorageCostBox,
  StorageCostText,
  ContentText,
  PreviewBox,
  NextButton,
  RecommendationBox,
} from "./styles";

const StorageCost = () => {
  const navigate = useNavigate();

  const handleOffloadingoptions = () => {
    navigate(questionRoute);
  };
  return (
    <>
      <StorageCostBox>
        <PreviewBox>
          <StorageCostText>Your Current Storage Costs</StorageCostText>
          <ContentText>
            <div>
              Given the information shared, we estimate that your excess
              inventory is costing you.
            </div>
            <ul>
              <li>5 cents per unit per day in storage.</li>
              <li>
                At 100 units per pallet and 25 pallets, you are spending $125 a
                day on storage
              </li>
              <li>Which amounts to an average of $3750 a month.</li>
            </ul>
          </ContentText>
        </PreviewBox>
        <RecommendationBox>
          <ContentText>
            We have aggregated some recommendations for you.
          </ContentText>
          <NextButton onClick={handleOffloadingoptions}>Next</NextButton>
        </RecommendationBox>
      </StorageCostBox>
    </>
  );
};
export default StorageCost;
