import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import PubLayout from "../layout/PubLayout";

//routes
import { authProtectedRoutes, publicRoutes, accessibleRoutes } from "./allRoutes";
import { useSelector } from "react-redux";

interface authUser {
  authUser: {
    isLoggedIn: boolean;
  };
}

const Index = () => {
  const token = localStorage.getItem("token");
  const { isLoggedIn } = useSelector((state: authUser) => state.authUser);

  return (
    <React.Fragment>
      <Routes>
        {!!token &&
          authProtectedRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={<AuthLayout> {route.component}</AuthLayout>}
                key={idx}
              />
            );
          })}
        {!isLoggedIn &&
          publicRoutes.map((route, idx) => (
            <Route 
              path={route.path} 
              element={
                (route.path === "/listing" || route.path === "/offer" || route.path === "/get-invoice") ? (
                  <PubLayout>{route.component}</PubLayout>
                ) : (
                  route.component
                )
              } 
              key={idx} 
            />
          ))}
          {
            accessibleRoutes.map((route, idx) => (
              <Route 
                path={route.path} 
                element={
                  (route.path === "/listing" || route.path === "/offer" || route.path === "/get-info" || route.path === "/tos" || route.path === '/confirm-info') ? (
                    <PubLayout>{route.component}</PubLayout>
                  ) : (
                    route.component
                  )
                } 
                key={idx} 
              />
            ))}
      </Routes>
    </React.Fragment>
  );
};

export default Index;
