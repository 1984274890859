import styled from "@emotion/styled";

export const ConfirmationBox = styled("div")`
  max-width: 955px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  min-height: 292px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    min-height: 340px;
  }
`;

export const ConfirmationHeaderText = styled("div")`
  color: #171717;
  font-size: 25px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 27px;
  gap: 16px;
  padding-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  font-style: normal;
  font-family: "ProximaNova-Medium" !important;
  font-weight: 400;
  line-height: 50px;
  padding-bottom: 25px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 34px;
  }
`;

export const ContentMessageText = styled("div")`
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-family: "ProximaNova-Medium" !important;
  font-weight: 400;
  line-height: 50px;
  padding-bottom: 25px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 34px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  max-width: 96px;
  width: 100%;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
