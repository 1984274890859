import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GetUser from "../../Utils/Get-user";
import axios from "axios";
import blank from '../../assets/images/blank.png';
import test_mil from '../../assets/images/test_products/test_prod_mil.jpg';
import test_momo from '../../assets/images/test_products/test_prod_momo.jpg';
import test_yums from '../../assets/images/test_products/test_prod_yums.webp';
import CPopup from "./confirmationPop";
import { useGetExcessId } from "../../Redux/api/User";

import { dataprocessingRoutes, dataUploadRoute } from "../../Routes/constants";

import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyInput from 'react-currency-input-field';


import {
  DataPreviewBox,
  DataPreviewText,
  PreviewBox,
  ContentText,
  Datatable,
  NextButton,
  ContextHeading,
  HeadingText,
  ButtonBox,
  ImgContainer,
  ItemNameText,
  DataUploadBox,
  DataUploadText,
  UploadBox,
  ListText,
  DescText,
  MoneyText,
  DescHeader,
  NeoDiv,
  InfoCard,
} from "./styles";

function createData(_id:any, itemName: any, upc: any, sellPrice: any, costPrice: any, quantity: any, location:any, expDate: any, endDate: any, sold:any, status: any, buyer: any, seller: any) {
  return { _id, itemName, upc, sellPrice, costPrice, quantity, location, expDate, endDate, sold, status, buyer, seller };
}

const rows =
  createData(
    "65f3942041b4984594c0d900",
    "Loading",
    "Loading",
    10.00,
    7.50,
    1000,
    "Loading",
    new Date('04/24/2024'),
    new Date('04/10/2999'),
    false,
    "Loading",
    "liquidator",
    "seller",
  );

const Listing = () => {

  const queryParameters = new URLSearchParams(window.location.search);
  const [item, setItem] = useState(rows);
  const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const listing = queryParameters.get("l");
  const time = queryParameters.get("d");
  const user = queryParameters.get("u");
  const email = queryParameters.get("e");
  const [makeOffer, setMakeOffer] = useState(false);
  const [offerAmt, setOfferAmt] = useState('');

  var total_cost = 0;
  var tax_rate = 0;

  var params = {}
  const expDateCheck = true;

  const currDate = new Date();
  console.log(currDate);

  console.log(listing);
  console.log(time);
  console.log(user);
  console.log(email);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_listing', { params: { listingID: listing, buyer: user ? user: email } });
        setItem(response.data.listing);
        setMadeOffer(response.data.offerFound);
      } catch (error) {
        console.error("Error grabbing listing:", error);
      }
    };
    fetchData();
  }, [listing]);
  
  
  const handleSubmit = async (e: any) => {
    console.log('submit');
    const params = {
      buyer: user ? user: email ,
      listing: listing,
      offerDate: currDate,
      registedBuyer: user ? true: false,
      seller: item.seller,
      total_cost: lotCost,
      amount: lotCost,
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/add_offer', params).then((response) => {
        console.log(response);
        setOpenModal(true);
      });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  }

  const handleSubmitNeo = async (e: any) => {
    console.log('submit');
    const params = {
      buyer: user ? user: email ,
      listing: listing,
      offerDate: currDate,
      registedBuyer: user ? true: false,
      seller: item.seller,
      total_cost: lotCost,
      amount: offerAmt,
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/add_offer', params).then((response) => {
        console.log(response);
        setOpenModal(true);
      });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const endDate = item.endDate ? new Date(item.endDate) : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  var userAllowed = true;
  if (item.buyer == user || item.buyer == email) {
    if(item.status === 'IP') {
      userAllowed = false;
    }
  }

  if (item.sold || currDate.getTime() > endDate.getTime()) {
    return (
      <div>
        <ContextHeading> <HeadingText> Deal has expired! Please check other listings! </HeadingText> </ContextHeading>
      </div>
    );
  }

  if(!email && !user) {
    return (
      <div>
        <ContextHeading> <HeadingText> Error grabbing user information, please check email again! </HeadingText> </ContextHeading>
      </div>
    )
  }

  

  const lotCost = parseFloat((item.sellPrice * item.quantity).toFixed(2));
  const tax = parseFloat((tax_rate * lotCost).toFixed(2));
  const totalCost = (lotCost + tax).toFixed(2);

  function getFormattedDate(date: any) {
    console.log(date);
    var year = date.getFullYear();
    console.log(year);
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  const formattedEndDate = getFormattedDate(endDate)
  const formattedExpDate = getFormattedDate(new Date(item.expDate))

  const handleCheckboxChange = (e: any) => {
		setMakeOffer(e.target.checked);
	};


  return (
    <div>
      <ContextHeading> <HeadingText> Email/Offer </HeadingText> </ContextHeading>
      <DataPreviewBox>
        <PreviewBox>

            <ImgContainer src={item.upc === '14100079477' ? test_mil : item.upc === '850281000000' ? test_momo : item.upc === '850036000000' ? test_yums : blank} alt="Product Image" />
            <InfoCard>
            <DescHeader> About this item: </DescHeader>
            <DescText>
            <ul>
              <li> UPC: {item.upc} </li>
              <li> Retail Price: <MoneyText> ${item.sellPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })} </MoneyText> </li>
              <li> Cost Price: {item.costPrice ? <MoneyText>${item.costPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })} </MoneyText> : 'No cost basis found, email us back for more info.'} </li>
            </ul>
            </DescText>
            </InfoCard>
        </PreviewBox>
        <PreviewBox>
        <InfoCard>
          <ItemNameText>{item.itemName}</ItemNameText>
          <ContentText>
            For Lot: ${lotCost.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            <br/>
            Est. Tax: To be calculated
            <br/>
            Total: ${lotCost.toLocaleString(undefined, { minimumFractionDigits: 2 })} + tax
          </ContentText>
          <ListText>
          <ul>
            <li> Expiration Date: {item.expDate ? formattedExpDate : 'No expiration date set.' }</li>
            <li> For Sale Until: {formattedEndDate} </li>
            <li> Available Quantity: {item.quantity.toLocaleString(undefined)} units</li>
          </ul>
          </ListText>
          </InfoCard>
          {madeOffer? 
            <NextButton onClick={handleSubmit} disabled>Offer has been made</NextButton>
          : <ButtonBox>
           <Dialog
             open={openModal}
             onClose={handleClose}
             maxWidth="md"
             fullWidth
             PaperProps={{
               style: {
                 overflowX: "hidden",
                 maxWidth: "665px",
                 width: "100%",
               },
             }}
           >
             <DialogTitle>
               <IconButton
                 edge="end"
                 color="inherit"
                 onClick={handleClose}
                 aria-label="close"
                 sx={{
                   position: "absolute",
                   right: 8,
                   top: 0,
                 }}
               >
                 <CloseIcon />
               </IconButton>
             </DialogTitle>
             <DialogContent>
               <CPopup />
             </DialogContent>
           </Dialog>
           <div>
           <label htmlFor="checkbox">Negotiate </label> {" "}
		
          <input
            type="checkbox"
            id="checkbox"
            checked={makeOffer}
            onChange={handleCheckboxChange}
          />
          </div>

           {makeOffer?
          <div>
            <NeoDiv>
            Input Offer Amount: {" "}
            <CurrencyInput
              id="input-example"
              name="input-name"
              placeholder="Please enter a number"
              defaultValue={lotCost}
              decimalsLimit={2}
              prefix="$"
              onValueChange={(value) => setOfferAmt(value? value: '0') }
            />
           </NeoDiv>
           

           <NextButton onClick={handleSubmitNeo}>
            Make Offer
           </NextButton>
           </div> 
           :
            <div>
           <NextButton onClick={handleSubmit}>
            Accept Offer
           </NextButton>
           </div>
          }
           
         </ButtonBox>
          
        }
        </PreviewBox>
      </DataPreviewBox>
    </div>
  );
};

export default Listing;
