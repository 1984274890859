import { useState } from "react";
import { Stepper, Step } from "@mui/material";

import Question from "./onboarding";

import { StepperBox, QontoConnector, QuestionBox } from "./styles";

const steps = [
  "",
  "Terms of service",
  "Location",
];

const Questions = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <QuestionBox>
      <StepperBox>
        <Stepper
          alternativeLabel
          activeStep={activeStep + 1}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={index}></Step>
          ))}
        </Stepper>
      </StepperBox>
      <Question setActiveStep={setActiveStep} activeStep={activeStep} />
    </QuestionBox>
  );
};
export default Questions;
