import Navbar from "react-bootstrap/esm/Navbar";
import { NavbarBrand, NavbarSignUp } from "./styles";

const NavBar = () => {
  return (
    <Navbar expand="lg">
      <NavbarBrand>
        <Navbar.Brand href="https://www.sotira.co/about">About</Navbar.Brand>
      </NavbarBrand>
      <NavbarBrand>
        <Navbar.Brand href="https://www.sotira.co/blog">Blog</Navbar.Brand>{" "}
      </NavbarBrand>
      <NavbarBrand>
        <Navbar.Brand href="https://www.sotira.co/contact">
          Contact
        </Navbar.Brand>
      </NavbarBrand>
      <NavbarSignUp>
        <Navbar.Brand href="/auth/register">Sign Up</Navbar.Brand>
      </NavbarSignUp>
    </Navbar>
  );
};
export default NavBar;
