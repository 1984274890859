import { useState, useRef, useEffect } from "react";
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import autoTable from "jspdf-autotable";
import { PDFDocument } from 'pdf-lib'
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
import PPopup from "./pickupPop";
    
import * as XLSX from 'xlsx';

import {
  DataPreviewBox,
  NextButton,
  ContextHeading,
  HeadingText,
  ProgressDiv,
  CostText,
  ConfirmationText,
  AddLocationBox,
  SectionBox,
  InfoBox,
} from "./styles";

interface OfferItem {
  _id: any;
  buyer: any;
  completion: any;
  listings: any;
  dateEnd: any;
  offerAmounts: any[];
  offerDates: any[];
  seller: any;
  shipping: any;
  status: any;
}

interface ListingItem {
  _id: any;
  itemName: any;
  upc: any;
  sellPrice: any;
  costPrice: any;
  quantity: any;
  location: any;
  expDate: any;
  endDate: any;
  sold: any;
  status: any;
  buyer: any;
  seller: any;
}

interface OfferItems {
  quantity: any;
  price: any;
  listingID: any;
}

interface Location {
  _id: any;
  name: any;
  address: any;
  city: any;
  state: any;
  zip: any;
  schedule: any;
}

const OfferDocs = () => {

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const autocompleteRef = useRef<any>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_PLACES_TEST || '',
    libraries: ['places'],
  });

  const queryParameters = new URLSearchParams(window.location.search);
  const [item, setItem] = useState<OfferItem>({} as OfferItem);
  const [listingItem, setListingItem] = useState<ListingItem[]>([]);
  const [offerItem, setOfferItem] = useState<OfferItems[]>([]);
  const [rawData, setRawData] = useState(Array<{ [key: string]: any }>);
  const [madeOffer, setMadeOffer] = useState(false);
  const [raw, setRaw] = useState(false);
  const [isBulk, setIsBulk] = useState(true);
  const [buyerTOS, setBuyerTOS] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAcpt, setIsAcpt] = useState(true);
  const [weight, setWeight] = useState('');
  const [pallet, setPallet] = useState('');
  const [unit, setUnit] = useState('');

  const offer = queryParameters.get("o");
  const time = queryParameters.get("d");
  const user = queryParameters.get("u");
  const email = queryParameters.get("e");
  const download = queryParameters.get("download");
  const [showAllInfo, setShowAllInfo] = useState(false);
  const [showAddLoc, setShowAddLoc] = useState(false);

  const [secondaryLoc, setSecondaryLoc] = useState<Location>(); 

  const[openModal, setOpenModal] = useState(false);

  const [location, setLocation] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    schedule: '',
  });

  
  var total_cost = 0;

  const currDate = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'offer/get_offer', { params: { offerID: offer, buyer: user ? user: email } });
        setItem(response.data.offer);
        setMadeOffer(response.data.offerFound);
        if(response.data.offer.status !== "ACPT" && response.data.offer.status !== "ACPTN") {
          setIsAcpt(false);
        }

        if(!response.data.offer?.buyerTOS) {
          setBuyerTOS(false);
        }

        if(response.data.offer?.sentRaw) {
          setRaw(true);
          const getData = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_raw_data', { params: { offerID: offer, buyer: user ? user: email } }); 
          setRawData(getData.data.data);
          setIsLoading(false);

          if(download === "12677a") {
            generateExcel(getData.data.data);
          }
        } else {
          if (response.data.offer?.listings) {
            const newListingItems = [];
            for (const listing of response.data.offer.listings) {
                const findListing = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_listing', { params: { listingID: listing } });
                const newListingItem = findListing.data.listing;
                newListingItem.endDate = newListingItem.endDate ? new Date(newListingItem.endDate) : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
                newListingItems.push(newListingItem);
            }

            setListingItem(newListingItems);
            setIsLoading(false);

            if (download === "12677a") {
                generateExcel(newListingItems);
            }

          }
        }

        if (response.data.offer?.bulk) {
          setIsBulk(true);
        } else {
          setIsBulk(false);
        }

        if(response.data.offer?.buyLocation) {
          if(!isEmpty(response.data.offer?.buyLocation)) {
            setLocation(response.data.offer?.buyLocation);
          }
        }

        if(response.data.offer?.sellLocation) {
          setSecondaryLoc(response.data.offer?.sellLocation);
        }

        if(response.data.offer?.weight) {
          setWeight(String(response.data.offer?.weight));
        }

        if(response.data.offer?.pallet) {
          setPallet(String(response.data.offer?.pallet));
        }

        if(response.data.offer?.weightUnit) {
          setUnit(response.data.offer?.weightUnit);
        }

      } catch (error) {
        //console.error("Error grabbing listing:", error);
      }
    };
    fetchData();
    
    
  }, [offer]);

  if (!raw) {
    for(let listing of listingItem) {

      total_cost += (listing.quantity ? listing.quantity : 0) * (listing.sellPrice ? listing.sellPrice : listing.costPrice);

    };

    total_cost = parseFloat(total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',', ''));

    
  } else {
      for (let listing of rawData) {
        if(listing?.Avail) {

          if(listing?.MFSRP) {
            total_cost += parseFloat(listing.MFSRP)* parseFloat(listing.Avail);
          }
        }
      }

      total_cost = parseFloat(total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',', ''));
  }

  function getFormattedDate(date: any) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  const generateExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    if (!raw) {
      const newData = filterData(data);
      ws = XLSX.utils.json_to_sheet(newData);
    } 
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currdate = date.getDate();
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'inventory list' + String(month + 1) + '/' + String(currdate) + '/' + String(year) + '.xlsx');
  };

  const filterData = (data: any) => {
    return data.map((item: any) => ({
        Name: item.itemName,
        Quantity: item.quantity,
    }));
  };

  const confirmPickup = async () => {
    setOpenModal(true);
  }

  const handleClose = () => {
    setOpenModal(false);
  };


  const showOptions = () => {
    if (isAcpt) {
      if(isLoading) {
        return (
          
          <div>
            <ProgressDiv> <CircularProgress /> </ProgressDiv>
            <NextButton onClick={generatePO} disabled>Download PO</NextButton>
            <NextButton onClick={generateBOL} disabled>Download BOL</NextButton>
            <NextButton onClick={checkPickup} disabled>Check Buyer Pickup Time</NextButton>
            <NextButton onClick={confirmPickup} disabled>Confirm Pickup</NextButton>
          </div>
          
        )
      } else {
        return (
          <div>
            <Dialog
             open={openModal}
             onClose={handleClose}
             maxWidth="md"
             fullWidth
             PaperProps={{
               style: {
                 overflowX: "hidden",
                 maxWidth: "665px",
                 width: "100%",
               },
             }}
           >
             <DialogTitle>
               <IconButton
                 edge="end"
                 color="inherit"
                 onClick={handleClose}
                 aria-label="close"
                 sx={{
                   position: "absolute",
                   right: 8,
                   top: 0,
                 }}
               >
                 <CloseIcon />
               </IconButton>
             </DialogTitle>
             <DialogContent>
               <PPopup location={secondaryLoc} offerDates={item?.offerDates} offerID={item._id}/>
             </DialogContent>
           </Dialog>
            <NextButton onClick={generatePO}>Download PO</NextButton>
            <NextButton onClick={generateBOL}>Download BOL</NextButton>
            <NextButton onClick={checkPickup}>Check Buyer Pickup Time</NextButton>
            <NextButton onClick={confirmPickup}>Confirm Pickup</NextButton>
          </div>
        )
      }
    } else {
      return (
        <div>
          There has been an error please contact us at support@sotira.co
        </div>
      )
    }
  }

  const handleShowInfo = (e:any) => {
    setShowAllInfo(!showAllInfo);
  };

  const handleShowAddress = (e:any) => {
    setShowAddLoc(!showAddLoc);
  }

  function isEmpty(obj: any) {
    if(obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }


  const showInfo = () => {
    return (
    <div> 
      {showAllInfo &&
      <div>
        {isEmpty(secondaryLoc) ?
        <DataPreviewBox>
          Waiting on buyer to update information
        </DataPreviewBox> :
        <DataPreviewBox>
          <div> Pickup Location: {" "}</div>
        <br />
        <label style={{marginLeft: '8px' }}>
          Address:
          <input
            name="address"
            value={secondaryLoc?.address}
            placeholder="Street Address"
            disabled={true}
            style={{ width: '100%', maxWidth: '500px', padding: '8px'}}
          />
        </label>
        <br />
        <label>
          City:
          <input
            name="city"
            value={secondaryLoc?.city}
            placeholder="City"
            disabled={true}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          State:
          <input
            name="state"
            value={secondaryLoc?.state}
            placeholder="State"
            disabled={true}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
        <br />
        <label>
          Zip Code:
          <input
            name="zip"
            value={secondaryLoc?.zip}
            placeholder="Zip Code"
            disabled={true}
            style={{ width: '100%', maxWidth: '500px', padding: '8px' }}
          />
        </label>
      </DataPreviewBox>
      }
      </div>
      }
    </div>
    )
    
  }

  const handleGetDate = (date: any) => {
    date = new Date(date);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    return month + ' ' + String(day) + ' ' + String(year);
  }

  var headers = listingItem.length > 0 ? Object.keys(listingItem[0]) : [];
  var data = listingItem;

  const generateBOL = async () => {
    const BOL_URL = 'https://cdn.prod.website-files.com/61dd2460f99ba00b4d9075ec/66efd09dad05806eb1f81d0c_BOL-Template.pdf';
    //const BOL_URL = 'https://pdf-lib.js.org/assets/dod_character.pdf';
    const formPdfBytes = await fetch(BOL_URL).then(res => res.arrayBuffer());
    //const formPdfBytes = await fetch(BOLPDF).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const date = new Date();
    const textDate = handleGetDate(date);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    fields.forEach(field => {
      const type = field.constructor.name
      const name = field.getName()
    })

    const dateField = form.getTextField('topmostSubform[0].Page1[0].Date[0]');
    dateField.setText(textDate);

    const pageField = form.getTextField('topmostSubform[0].Page1[0].Page[0]');
    pageField.setText('1');

    const fromNameField = form.getTextField('topmostSubform[0].Page1[0].fromName[0]');
    fromNameField.setText('Sotira');

    const fromAddField = form.getTextField('topmostSubform[0].Page1[0].fromAddress[0]');
    fromAddField.setText(secondaryLoc?.address);

    const fromCityStField = form.getTextField('topmostSubform[0].Page1[0].fromCityStZip[0]');
    const fromCityCombo = secondaryLoc?.city + ', ' + secondaryLoc?.state + ' ' + secondaryLoc?.zip;
    fromCityStField.setText(fromCityCombo);

    const toNameField = form.getTextField('topmostSubform[0].Page1[0].toName[0]');
    toNameField.setText(location.name);

    const toAddField = form.getTextField('topmostSubform[0].Page1[0].toAddress[0]');
    toAddField.setText(location.address);

    const toCityStField = form.getTextField('topmostSubform[0].Page1[0].toCityStZip[0]');
    const toCityCombo = location.city + ', ' + location.state + ' ' + location.zip;
    toCityStField.setText(toCityCombo);

    const quantityField = form.getTextField('topmostSubform[0].Page1[0]._1huQty[0]');
    quantityField.setText(pallet);

    const quantityTypeField = form.getTextField('topmostSubform[0].Page1[0]._1huType[0]');
    quantityTypeField.setText('pallet');

    const weightField = form.getTextField('topmostSubform[0].Page1[0]._1crweight[0]');
    const fullWeight = weight + ' ' + unit;
    weightField.setText(fullWeight);

    const totalQuantityField = form.getTextField('topmostSubform[0].Page1[0].huQtyTotal[0]');
    totalQuantityField.setText(pallet);

    const totalWeightField = form.getTextField('topmostSubform[0].Page1[0].crWeightTotal[0]');
    totalWeightField.setText(fullWeight);
    
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    saveAs(pdfDataUri, 'BOL.pdf');
  }

  const checkPickup = async () => {
    console.log('pickup');
  }

  const generatePO = async () => {
    var title = user ? user: email || '';
    var userInfo: any = {}
    if (user) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/get_invoice_info', { params: { userID: user } });
        userInfo = {address1: response.data.address1 || '', address2: response.data.address2 || ''}
        title = response.data.firstName + " " + response.data.lastName;
      } catch (error) {
        console.error("Error Submitting Order:", error);
      }
    }

    var address = userInfo.address1 || '';
    var cityZip = userInfo.address2 || '';

    var forText = "Liquidation Inventory Purchase";


    const doc = new jsPDF();
    
    doc.setFont('times', 'bold');
    doc.setFontSize(11);

    // Header
    doc.text('Sotira Fintech Co.', 10, 10);
    doc.setFont('times', 'normal');
    doc.text('Phone: 650 460 9890', 10, 15);

    // Invoice Title and Date
    doc.setFont('times', "bold");
    doc.text('PURCHASE ORDER', 150, 10);
    doc.text(`DATE: ${new Date().toLocaleDateString()}`, 150, 15);

    // To and Address
    doc.text('TO:', 10, 35);
    doc.setFont('times', 'normal');
    doc.text(title, 10, 40);
    doc.text(address, 10, 45);
    doc.text(cityZip, 10, 50);

    // For and For Text
    doc.setFont('times', "bold");
    doc.text('FOR:', 150, 35);
    doc.setFont('times', 'normal');
    doc.text(forText, 150, 40);

    if (!raw) {
      data = filterData(data);
      headers = data.length > 0 ? Object.keys(data[0]) : [];
    } 

    //const tableData = data.map((row: any) => headers.map((col: any) => row[col.dataField]));
    var tableData = data.map((row: any) => headers.map(headers => row[headers]));
    if (raw) {
      headers = rawData.length > 0 ? Object.keys(rawData[0]) : [];
      tableData = rawData.map((row: any) => headers.map(headers => row[headers]));
    }

    if (raw) {
      doc.textWithLink("Check Offer Here", 10, 55, {url: "https://sotira.app/review-offer?" + queryParameters.toString()})
    }
    autoTable(doc, {
      head: [headers],
      body: tableData,
      pageBreak: 'auto',
      startY: 60,
      styles: { font: 'times', fontSize: 11, overflow: 'hidden' },
    });

    var cost = item.offerAmounts[item.offerAmounts.length - 1];

    if(item.shipping > 0) {
      cost = Number(cost) + Number(item.shipping);
    }
    let finalY = (doc as any).lastAutoTable.finalY;

    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const limitHeight = pageHeight - margin;

    if(finalY + 80 > limitHeight) {
      doc.addPage();
      doc.text(`Total Payment will be sent via Sotira portal`, 10, 15);
    } else {
      doc.text(`Total Payment will be sent via Sotira portal`, 10, 15);
    }
    doc.save('invoice.pdf');
  }

  return (
    <div>
      <ContextHeading> <HeadingText> Next Steps </HeadingText> </ContextHeading>
      <InfoBox>
      <SectionBox>
      <CostText> Paperwork Items </CostText>
      <DataPreviewBox>
        <div> {showOptions()} </div>
      </DataPreviewBox>
      </SectionBox>
      </InfoBox>
    </div>
  );
};

export default OfferDocs;
