import React, { useEffect } from "react";
import Route from "./Routes";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { authUser } from "./Redux/auth/reducer";

import { GoogleOAuthProvider } from '@react-oauth/google';

interface authuser {
  authUser: {
    isLoggedIn: boolean;
  };
}

const App = () => {
  const clientId =
    "381059260317-rl1qvckjoef2o5niim5h2um7nspakgv5.apps.googleusercontent.com";
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: authuser) => state.authUser);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      jwt_decode(token)
        ? dispatch(authUser({ isLoggedIn: true }))
        : dispatch(authUser({ isLoggedIn: false }));
    } else {
      dispatch(authUser({ isLoggedIn: false }));
    }
  }, [isLoggedIn, dispatch]);

  return (
    <GoogleOAuthProvider clientId="381059260317-rl1qvckjoef2o5niim5h2um7nspakgv5.apps.googleusercontent.com">
    <div className="App">
      <ToastContainer autoClose={2000} limit={5} />
      <Route />
    </div>
    </GoogleOAuthProvider>
  );
};

export default App;
