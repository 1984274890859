import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";
import GetUser from "../../Utils/Get-user";
import moment from "moment";
import { getExcessobj } from "../../Utils/getexcessobj";
import { Card, CardContent, CircularProgress } from "@mui/material";

import { useGetExcessId } from "../../Redux/api/User";

import { PreviousRecomandationBox, PreviousRecomandationText } from "./styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: "#EDEDED",
  },
  cell: {
    fontSize: "14px !important",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "18px !important",
    fontWeight: 700,
    fontFamily: "ProximaNova !important",
    color: "#000",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));

const PreviousRecommendation = () => {
  const classes = useStyles();
  const {
    data: { data: { excess = [] } = {} } = {},
    isLoading,
    isFetching,
  } = useGetExcessId(GetUser());

  return (
    <>
      <PreviousRecomandationBox excessdata={excess?.length}>
        <PreviousRecomandationText>
          Previous Recommendations{" "}
        </PreviousRecomandationText>
        {isLoading || isFetching ? (
          <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
            <CardContent className="pt-1 d-flex justify-content-center">
              <CircularProgress sx={{ color: "#41c175" }} />
            </CardContent>
          </Card>
        ) : (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.header}>
                    File Name
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Date of submission
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Data Upload
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {excess?.map((excessdata: any, index: Number) => (
                  <TableRow>
                    <TableCell align="center" className={classes.cell}>
                      {excessdata.fileName ? excessdata.fileName : "-"}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {moment(excessdata.date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {getExcessobj(excessdata)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PreviousRecomandationBox>
    </>
  );
};
export default PreviousRecommendation;
