import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from '@mui/material/TablePagination';
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";

const createData = (
  productId: string,
  size: string,
  unit: number,
  date: string
) => {
  return { productId, size, unit, date };
};

const previewData = [
  createData("GA-23189", "S", 48, " 07 / 03 / 2021"),
  createData("GA-23190", "M", 35, " 07 / 03 / 2021"),
  createData("GA-23191", "L", 68, " 07 / 03 / 2021"),
];

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: "#EDEDED",
  },
  cell: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "ProximaNova !important",
    color: "#000",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));

interface DataTableProps {
  foundData: any[];
  missingData: any[];
  showFound: boolean;
  showMissing: boolean;
}

const DataTable: React.FC<DataTableProps> = ({ foundData, missingData, showFound, showMissing })=> {
  const classes = useStyles();
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(5);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [missingPage, setMissingPage] = useState(0);
  const [missingRowsPerPage, setMissingRowsPerPage] = useState(5);
  const [missingPaginatedData, setMissingPaginatedData] = useState<any[]>([]);

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(foundData.slice(startFoundIndex, endFoundIndex));
    const startMissingIndex = missingPage * missingRowsPerPage;
    const endMissingIndex = startMissingIndex + missingRowsPerPage;
    setMissingPaginatedData(missingData.slice(startMissingIndex, endMissingIndex));
  }, [foundData, missingData, foundPage, foundRowsPerPage, missingPage, missingRowsPerPage]);

  const handleFoundChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleMissingChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setMissingPage(newPage);
  };

  const handleMissingChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMissingRowsPerPage(parseInt(event.target.value, 10));
    setMissingPage(0);
  };

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
          {showFound && 
            <TableRow>
              <TableCell align="center" className={classes.header}>
                UPC
              </TableCell>
              <TableCell align="center" className={classes.header}>
                Item Name
              </TableCell>
              <TableCell align="center" className={classes.header}>
                Quantity
              </TableCell>
              <TableCell align="center" className={classes.header}>
                Sell Price
              </TableCell>
              <TableCell align="center" className={classes.header}>
                Sell By Date
              </TableCell>
              <TableCell align="center" className={classes.header}>
                Location (ZIP)
              </TableCell>
            </TableRow>
          }
          {
            showMissing &&
            <TableRow>
              <TableCell align="center" className={classes.header}>
                UPC
              </TableCell>
            </TableRow>
          }
          </TableHead>
          <TableBody>
            {showFound && foundPaginatedData.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow key={item._id}>
                  <TableCell>{item.upc}</TableCell>
                  <TableCell>{item.itemName}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.sellPrice}</TableCell>
                  <TableCell>{item.sellDate}</TableCell>
                  <TableCell>{item.location}</TableCell>
                </TableRow>

              </React.Fragment>
            ))}
            {showMissing && missingPaginatedData.map((upc, index) => (
              <TableRow key={index}>
                <TableCell>{upc}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showFound && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={foundData.length}
          rowsPerPage={foundRowsPerPage}
          page={foundPage}
          onPageChange={handleFoundChangePage}
          onRowsPerPageChange={handleFoundChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem', 
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem',
            },
          }}
        />
      )}
      {showMissing && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={missingData.length}
          rowsPerPage={missingRowsPerPage}
          page={missingPage}
          onPageChange={handleMissingChangePage}
          onRowsPerPageChange={handleMissingChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem', 
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem',
            },
          }}
        />
      )}
    </div>
  );
};

export default DataTable;