import styled from "@emotion/styled";

export const PreviousRecomandationBox = styled("div")`
    width: 100%;
    margin: auto;
    margin-top: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PreviousRecomandationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  padding-bottom: 20px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  width: 100%;
  margin-top: 5%;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
