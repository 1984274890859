import styled from "@emotion/styled";

export const DataPreviewBox = styled("div")`
  max-width: 860px;
  width: 100%;
  min-height: 409px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DataPreviewText = styled("div")`
  color: #171717;
  font-size: 35px;
  font-style: normal;
  font-family: "ProximaNova" !important;
  font-weight: 700;
  line-height: 124%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;


export const DashboardContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}
`
export const DashboardCard = styled("div")`
  background-color: #F6F6F6;
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  max-width: 300px;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const DashboardHead = styled("div")`
  font-size: 25px;
  font-style: normal;
  text-align: center;
  font-family: "ProximaNova" !important;
  font-weight: 700;
`

export const DashboardDesc = styled("div")`
  font-size: 20px;
  text-align: center;
  font-style: normal;
  font-family: "ProximaNova" !important;
`
export const DashboardButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 97px;
  max-height: 55px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;



export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  font-style: normal;
  font-family: "ProximaNova-Medium" !important;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 97px;
  max-height: 55px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
