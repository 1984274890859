import { useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";

import {
  usePostAddBusinessInfo,
  useLazyGetUserNameQuery,
} from "../../Redux/api/User";

import { homeRoute } from "../../Routes/constants";

import "../../assets/styling/login.css";
import { LoginButton, BusinessInfoBox } from "./styles";
import GetUser from "../../Utils/Get-user";
import { authUser } from "../../Redux/auth/reducer";

const BusinessInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addBusinessInfo]: any = usePostAddBusinessInfo();
  const [getuserName]: any = useLazyGetUserNameQuery();

  const [busiName, setBusiName] = useState("");
  const [busiLink, setBusiLink] = useState("");

  function validateForm() {
    return busiName.length > 0 && busiLink.length > 0;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const payload = {
        company: busiName,
        link: busiLink,
        userID: GetUser(),
      };
      await addBusinessInfo(payload);
      const { data } = await getuserName(GetUser());
      localStorage.setItem("FirstName", data?.firstName);
      localStorage.setItem("LastName", data?.lastName);
      dispatch(authUser({ isLoggedIn: true }));
      navigate(homeRoute);
    } catch (err) {
      toast.error("Try again, information was not processed properly");
    }
  };
  return (
    <>
      <BusinessInfoBox>
        <Form className="business-form">
          <Form.Group className="form-field" style={{ paddingBottom: "20px" }}>
            <Form.Label className="title">Business Name</Form.Label>
            <Form.Control
              autoFocus
              type="businessName"
              value={busiName}
              placeholder="Enter business name"
              onChange={(e) => setBusiName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="form-field" style={{ paddingBottom: "20px" }}>
            <Form.Label className="title">Business Website</Form.Label>
            <Form.Control
              autoFocus
              type="businessWebsite"
              value={busiLink}
              placeholder="Enter website name"
              onChange={(e) => setBusiLink(e.target.value)}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <LoginButton disabled={!validateForm()} onClick={handleSubmit}>
              Add Business information
            </LoginButton>
          </div>
        </Form>
      </BusinessInfoBox>
    </>
  );
};
export default BusinessInfo;
