import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GetUser from "../../Utils/Get-user";
import axios from "axios";
import blank from '../../assets/images/blank.png';
import CPopup from "./confirmationPop";
import TPopup from "./tosPop";
import { useGetExcessId } from "../../Redux/api/User";

import { dataprocessingRoutes, dataUploadRoute, listingRoute } from "../../Routes/constants";

import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyInput from 'react-currency-input-field';

import DataTable from "./DataTable"
import DataTableRaw from "./DataTableRaw"

import * as XLSX from 'xlsx';

import {
  DataPreviewBox,
  DataPreviewText,
  PreviewBox,
  ContentText,
  Datatable,
  NextButton,
  ContextHeading,
  HeadingText,
  CostText,
  PrevCostText,
  ButtonBox,
  ImgContainer,
  ItemNameText,
  DataUploadBox,
  DataUploadText,
  UploadBox,
  InputNeo,
  NeoDiv,
  NeoCheckDiv,
  ActionBox,
  RejButton,
  SuccessButton,
  DownloadButton,
  NewText,
} from "./styles";

function createData(_id:any, itemName: any, upc: any, sellPrice: any, costPrice: any, quantity: any, location:any, expDate: any, endDate: any, sold:any, status: any, buyer: any, seller: any) {
  return { _id, itemName, upc, sellPrice, costPrice, quantity, location, expDate, endDate, sold, status, buyer, seller };
}

function createOffer(_id: any, buyer:any, completion: any, listings: any, dateEnd: any, offerDate: any, seller: any, status:any ) {
  return {_id, buyer, completion, listings, dateEnd, offerDate, seller, status}
} 

const rows = [createData(
  "65f3942041b4984594c0d900",
  "Milano Mint Chocolate Cookies 7oz test",
  "14100079478",
  10.00,
  7.50,
  1000,
  "08003",
  new Date('04/24/2024'),
  new Date('04/10/2024'),
  false,
  "open",
  "liquidator",
  "seller",
)
]

const offerRows = createOffer (
  "6619bd2dc2b9734d50c541f9",
  "Buyer",
  false,
  ["65f393e941b4984594c0d8fe", "65f3942041b4984594c0d900"],
  new Date('04/24/2024'),
  new Date('04/10/2024'),
  "seller",
  "IP",
);

interface OfferItem {
  _id: any;
  buyer: any;
  completion: any;
  listings: any;
  dateEnd: any;
  offerAmounts: any[];
  offerDate: any[];
  seller: any;
  status: any;
}


interface ListingItem {
  _id: any;
  itemName: any;
  upc: any;
  sellPrice: any;
  costPrice: any;
  quantity: any;
  location: any;
  expDate: any;
  endDate: any;
  sold: any;
  status: any;
  buyer: any;
  seller: any;
}

interface OfferItems {
  quantity: any;
  price: any;
  listingID: any;
}

const Offer = () => {

  const queryParameters = new URLSearchParams(window.location.search);
  const [item, setItem] = useState<OfferItem>({} as OfferItem);
  const [listingItem, setListingItem] = useState<ListingItem[]>([]);
  const [offerItem, setOfferItem] = useState<OfferItems[]>([]);
  const [rawData, setRawData] = useState(Array<{ [key: string]: any }>);
  const [showOffers, setShowOffers] = useState(false);
  const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [makeOffer, setMakeOffer] = useState(false);
  const [offerAmt, setOfferAmt] = useState('');
  const [raw, setRaw] = useState(false);
  const [isBulk, setIsBulk] = useState(true);
  const [buyerTOS, setBuyerTOS] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const offer = queryParameters.get("o");
  const time = queryParameters.get("d");
  const user = queryParameters.get("u");
  const email = queryParameters.get("e");
  const download = queryParameters.get("download");
  
  var total_cost = 0;
  var tax_rate = 0;

  var params = {}
  const expDateCheck = true;

  const currDate = new Date();
  //console.log(currDate);

  //console.log(offer);
  //console.log(time);
  //console.log(user);
  //console.log(email);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'offer/get_offer', { params: { offerID: offer, buyer: user ? user: email } });
        setItem(response.data.offer);
        setMadeOffer(response.data.offerFound);

        console.log(response.data.offer);

        if(!response.data.offer?.buyerTOS) {
          setBuyerTOS(false);
        }

        if(response.data.offer?.sentRaw) {
          setRaw(true);
          const getData = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_raw_data', { params: { offerID: offer, buyer: user ? user: email } }); 
          setRawData(getData.data.data);
          if(download === "12677a") {
            generateExcel(getData.data.data);
          }
        } else {
          if (response.data.offer?.listings) {
            const newListingItems = [];
            for (const listing of response.data.offer.listings) {
                const findListing = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_listing', { params: { listingID: listing } });
                const newListingItem = findListing.data.listing;
                newListingItem.endDate = newListingItem.endDate ? new Date(newListingItem.endDate) : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
                newListingItems.push(newListingItem);
            }
            

            setListingItem(newListingItems);
            setIsLoading(false);

            if (download === "12677a") {
                generateExcel(newListingItems);
            }
            // for (const listing of response.data.offer.listings) {
            //   const findListing = await axios.get(process.env.REACT_APP_API_BASE_URL + 'listing/get_listing', { params: { listingID: listing } });
            //   //console.log(findListing);
            //   const newListingItem = findListing.data.listing;
            //   newListingItem.endDate = newListingItem.endDate ? new Date(newListingItem.endDate) : new Date(currDate.getTime() + 7 * 24 * 60 * 60 * 1000);
            //   //console.log(newListingItem);
            //   setListingItem(prevListingItem => [...prevListingItem, newListingItem]);
            //   //console.log(listingItem);
            // }
            // if(download === "12677a") {
            //   generateExcel(listingItem);
            // }
          }
        }

        if (response.data.offer?.bulk) {
          setIsBulk(true);
        } else {
          setIsBulk(false);
        }

      } catch (error) {
        //console.error("Error grabbing listing:", error);
      }
    };
    fetchData();

    
  }, [offer]);

  if (!raw) {
    for(let listing of listingItem) {

      total_cost += (listing.quantity ? listing.quantity : 0) * (listing.sellPrice ? listing.sellPrice : listing.costPrice);

    };

    total_cost = parseFloat(total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',', ''));

    
  } else {
      for (let listing of rawData) {
        if(listing?.Avail) {

          if(listing?.MFSRP) {
            total_cost += parseFloat(listing.MFSRP)* parseFloat(listing.Avail);
          }
          
        }
        if(listing['Total Cost']) {
          total_cost += parseFloat(listing['Total Cost'])
        }
      }

      total_cost = parseFloat(total_cost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replaceAll(',', ''));
  }


  
  
  const handleSubmit = async (e: any) => {
    console.log('submit');
    const params = {
      buyer: user ? user: email ,
      offerID: offer,
      offerDate: currDate,
      registedBuyer: user ? true: false,
      seller: item.seller,
      amount: item.offerAmounts[item.offerAmounts.length - 1]? item.offerAmounts[item.offerAmounts.length - 1]: total_cost,
      total_cost: total_cost,
      type: 'buyer',
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/update_offer', params).then((response) => {
        console.log(response);
        setOpenModal(true);
      });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const handleCloseTOS = () => {
    setBuyerTOS(true);
    window.location.reload();
  };

  const handleSubmitNeo = async (e: any) => {
    console.log('submit');
    const params = {
      buyer: user ? user: email ,
      offerID: offer,
      offerDate: currDate,
      registedBuyer: user ? true: false,
      seller: item.seller,
      amount: Number(offerAmt),
      total_cost: total_cost,
      type: 'buyer',
    };
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + 'offer/update_offer', params).then((response) => {
        console.log(response);
        setOpenModal(true);
      });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  }

  const handleCheckboxChange = (e: any) => {
		setMakeOffer(e.target.checked);
	};

  const handleChange = (e: any) => {
    const { value } = e.target;
    const [, decimal] = value.split(".");
    if (decimal?.length < 3 || !value.includes(".")) {
      setOfferAmt(value);
    }
  };

  


  function getFormattedDate(date: any) {
    //console.log(date);
    var year = date.getFullYear();
    //console.log(year);
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  const handleShowPrevious = (e:any) => {
    setShowOffers(!showOffers);
  };

  const generateExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    if (!raw) {
      const newData = filterData(data);
      ws = XLSX.utils.json_to_sheet(newData);
    } 
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currdate = date.getDate();
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'inventory list' + String(month + 1) + '/' + String(currdate) + '/' + String(year) + '.xlsx');
  };

  const filterData = (data: any) => {
    return data.map((item: any) => ({
        upc: item.upc,
        name: item.itemName,
        quantity: item.quantity,
        'sell price': item.sellPrice,
        expiration: item.expDate,
        location: item.location,
    }));
  };

  const showTOS = () => {
    if (!buyerTOS) {
      return (
        <Dialog
             open={!buyerTOS}
             onClose={handleCloseTOS}
             maxWidth="md"
             fullWidth
             PaperProps={{
               style: {
                 overflowX: "hidden",
                 maxWidth: "665px",
                 width: "100%",
               },               
             }}
           >
             <DialogTitle>
             </DialogTitle>
             <DialogContent>
               <TPopup offerID={item._id} />
             </DialogContent>
           </Dialog>
      )
    }
  }

  const showOptions = () => {
    if (item.status === "IP" || item.status === "offered") {
      if (item.offerAmounts && item.offerAmounts.length%2 === 1) {
        return (
          <NextButton onClick={handleSubmit} disabled>Waiting on seller</NextButton>
        )
      } else if (item.offerAmounts.length%2 === 0) {
        return (
          <ButtonBox>
           <Dialog
             open={openModal}
             onClose={handleClose}
             maxWidth="md"
             fullWidth
             PaperProps={{
               style: {
                 overflowX: "hidden",
                 maxWidth: "665px",
                 width: "100%",
               },
             }}
           >
             <DialogTitle>
               <IconButton
                 edge="end"
                 color="inherit"
                 onClick={handleClose}
                 aria-label="close"
                 sx={{
                   position: "absolute",
                   right: 8,
                   top: 0,
                 }}
               >
                 <CloseIcon />
               </IconButton>
             </DialogTitle>
             <DialogContent>
               <CPopup />
             </DialogContent>
           </Dialog>
           <NeoCheckDiv>
          <label htmlFor="checkbox">Negotiate </label> {" "}
		
          <input
            type="checkbox"
            id="checkbox"
            checked={makeOffer}
            onChange={handleCheckboxChange}
          />
          </NeoCheckDiv>


          {makeOffer?
          <div>
            <NeoDiv>
            Input Offer Amount: {" "}
            <CurrencyInput
              id="input-example"
              name="input-name"
              placeholder="Please enter a number"
              defaultValue={item.offerAmounts[item.offerAmounts.length - 1]? item.offerAmounts[item.offerAmounts.length - 1]: total_cost }
              decimalsLimit={2}
              prefix="$"
              onValueChange={(value) => setOfferAmt(value? value: '0') }
            />
           </NeoDiv>
           

           <NextButton onClick={handleSubmitNeo}>
            Make Offer
           </NextButton>
           </div> 
           :
            <ActionBox>
           <NextButton onClick={handleSubmit}>
            Accept Offer
           </NextButton>
           </ActionBox>
          }
         </ButtonBox>
        )
      } else {
        console.log("old offer");
        return (
          <NextButton onClick={handleSubmit} disabled>Waiting on buyer</NextButton>
        )
      }
    } else if (item.status === "ACPT" || item.status === "ACPTN") {
      return (
        <SuccessButton onClick={handleSubmit} disabled> Accepted - See next steps </SuccessButton>
      )
    } else {
      return (
        <NextButton onClick={handleSubmit} disabled> Something has gone wrong - contact us at sotira@sotira.co </NextButton>
      )
    }
  }


  const handleUpdateCost = (listingID: string, price: number) => {
    console.log(`Updating cost for item ${listingID} to ${price}`);
    setOfferItem((prevOfferItem) => {
      const itemIndex = prevOfferItem.findIndex(item => item.listingID === listingID);
      if (itemIndex !== -1) {
        const updatedOfferItem = [...prevOfferItem];
        updatedOfferItem[itemIndex].price = price;
        return updatedOfferItem;
      }
      return [...prevOfferItem, { listingID, price, quantity: 0 }];
    });
    console.log(offerItem);
  };

  const handleUpdateQuantity = (listingID: string, quantity: number) => {
    console.log(`Updating quantity for item ${listingID} to ${quantity}`);
    setOfferItem((prevOfferItem) => {
      const itemIndex = prevOfferItem.findIndex(item => item.listingID === listingID);
      if (itemIndex !== -1) {
        const updatedOfferItem = [...prevOfferItem];
        updatedOfferItem[itemIndex].quantity = quantity;
        return updatedOfferItem;
      }
      return [...prevOfferItem, { listingID, quantity, price: 0 }];
    });
    console.log(offerItem);
  };

  return (
    <div>
      <ContextHeading> <HeadingText> Email/Offer </HeadingText> </ContextHeading>
      {raw? <DataTableRaw offerData = { rawData } bulk = {isBulk}/> :
      <DataTable offerData ={ listingItem } bulk = {isBulk} isLoading = {isLoading} onUpdateCost={handleUpdateCost} onUpdateQuantity={handleUpdateQuantity}/> 
      }
      <ButtonBox> <DownloadButton onClick={() => generateExcel(raw? rawData: listingItem)}>Download inventory data</DownloadButton> </ButtonBox>
      
      <CostText> Total Cost: ${total_cost.toLocaleString(undefined, { minimumFractionDigits: 2 })} </CostText>

      {(item.offerAmounts && item.offerAmounts.length > 0) && (<div> 
      
      {item.offerAmounts.map((amount, index) => {
        console.log(index);
        console.log(amount);
        if (index === item.offerAmounts.length - 1) {
          return (<CostText key={amount}> Current offer at <NewText> ${amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </NewText> </CostText>)
        } else {
          if (showOffers) {
            return (
              <PrevCostText key={amount}> Previous offers at ${amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </PrevCostText>
            )
          } 
        }
      })}
      </div>)}

      {!showOffers && item.offerAmounts && item.offerAmounts.length > 1 && 
        <CostText onClick={handleShowPrevious}> Show Previous Offers </CostText>
      }

      <DataPreviewBox>
        <div> {showOptions()} </div>
      </DataPreviewBox>

      <DataPreviewBox>
        <div> {showTOS()} </div>
      </DataPreviewBox>
    </div>
  );
};

export default Offer;
