import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";

const createData = (
  productId: string,
  size: string,
  unit: number,
  date: string
) => {
  return { productId, size, unit, date };
};

const previewData = [
  createData("GA-23189", "S", 48, " 07 / 03 / 2021"),
  createData("GA-23190", "M", 35, " 07 / 03 / 2021"),
  createData("GA-23191", "L", 68, " 07 / 03 / 2021"),
];

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
  },
  table: {
    backgroundColor: "#EDEDED",
  },
  cell: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "ProximaNova !important",
    color: "#000",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));

const DataTable = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.header}>
              Product ID
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Size
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Number of Units
            </TableCell>
            <TableCell align="center" className={classes.header}>
              Date Acquired
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previewData.map((row) => (
            <TableRow key={row.productId}>
              <TableCell align="center" className={classes.cell}>
                {row.productId}
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                {row.size}
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                {row.unit}
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                {row.date}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default DataTable;
