import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";

import { logoutUserSuccess } from "../../Redux/login/reducer";
import { homeRoute, logoutRoute } from "../../Routes/constants";
import { authUser } from "../../Redux/auth/reducer";

import SideBarMenuList from "./SideBarMenuList";

import { sideBarList } from "../../constant";

interface SubMenuListProp {
  subList: any;
}

const menuName = ["Settings", "Log out"];

const SubMenuList = ({ subList }: SubMenuListProp) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const handleClick = (subList: any) => {
    if (subList.url) {
      if (subList.url === logoutRoute) {
        dispatch(logoutUserSuccess());
        dispatch(authUser({ isLoggedIn: false }));
        navigate(homeRoute);
      } else {
        navigate(subList.url);
      }
    }
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const recurtionFn = (MENU: object[]) => {
      MENU.forEach(({ children }: { children?: any }) => {
        if (!!children) {
          recurtionFn(children);
        } else {
          if (
            pathname
              .toLowerCase()
              .includes(
                (subList.url || subList.name).toLowerCase().replace(/ /g, "")
              )
          ) {
            setOpen(true);
            setActive(true);
          } else {
            setOpen(false);
            setActive(false);
          }
        }
      });
    };
    recurtionFn(sideBarList);
  }, [pathname, subList.name, subList.url]);

  return (
    <>
      <ListItem
        key={subList.name}
        disablePadding
        sx={{ display: "block", background: active ? "#F3F3F3" : "#ffff" }}
      >
        <ListItemButton
          onClick={() => handleClick(subList)}
          sx={{
            gap: "10px",
            paddingTop: 0,
            paddingLeft: "26px",
            paddingBottom: "10px",
            pl: subList.parentName
              ? subList.level > 1
                ? `${subList.level * 12 + 32}px`
                : "32px"
              : 2,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "unset",
            }}
          >
            {subList?.icon}
          </ListItemIcon>
          <ListItemText
            primary={subList.name}
            sx={{
              "& span": {
                fontSize: menuName.includes(subList.name) ? "18px" : "20px",
                fontWeight: 400,
                fontFamily: "ProximaNova !important",
                color: menuName.includes(subList.name) ? "#7B7B7B" : "#141414",
              },
            }}
          />
          {subList?.children?.length &&
            (!open ? (
              <KeyboardArrowRightIcon
                style={{
                  minWidth: "unset",
                  fontSize: "18px",
                }}
              />
            ) : (
              <ExpandMore
                style={{
                  minWidth: "unset",
                  fontSize: "18px",
                }}
              />
            ))}
        </ListItemButton>
      </ListItem>

      <Collapse in={open}>
        {subList?.children?.length ? (
          <SideBarMenuList menuItem={subList?.children || []} />
        ) : null}
      </Collapse>
    </>
  );
};

export default SubMenuList;
