import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { offloadingoptionRoutes } from "../../Routes/constants";

import DisposalIcon from "../../assets/images/svg/Disposal.svg";
import LiquidateIcon from "../../assets/images/svg/Liquidate.svg";
import Private_buyerIcon from "../../assets/images/svg/Private_buyer.svg";

import {
  OptionsBox,
  OffloadingOptionsText,
  Offloadingheader,
  OptionsFrame,
  StartButton,
  OptionsFrameBox,
  OptionsFrameHeader,
  OptionsFrameContent,
  EstimatedText,
  AdditionalOptionButton,
  OptionsImages,
  AnalysisButton,
} from "./styles";

const AdditionalOptions = () => {
  const navigate = useNavigate();

  const handleOffloadingOptions = () => {
    navigate(offloadingoptionRoutes);
  };
  return (
    <>
      <OptionsBox>
        <Offloadingheader>
          <OffloadingOptionsText>Offloading Options</OffloadingOptionsText>
          <div className="option-text">
            Here’s what we recommend for your business.
          </div>
        </Offloadingheader>
        <OptionsFrameBox>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={DisposalIcon} alt="DisposalImage" />
                </OptionsImages>
                <OptionsFrameHeader>
                  Destruction and Disposal
                </OptionsFrameHeader>
                <OptionsFrameContent>
                  We can help you destroy and dispose of your excess shoes in an
                  environmentally friendly way. Given the material of your
                  shoes, we can also help you upcycle or recycle your shoes.
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Cost: <span>$600</span>
                  {""}per pallet
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={LiquidateIcon} alt="LiquidateImage" />
                </OptionsImages>
                <OptionsFrameHeader>Liquidate</OptionsFrameHeader>
                <OptionsFrameContent>
                  We’ve found a few liquidation options with an estimated
                  timeline of 3 weeks.
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Profit: <span>$3,000</span>
                  {""}per pallet
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={Private_buyerIcon} alt="PrivatebuyerImage" />
                </OptionsImages>
                <OptionsFrameHeader>Find a Private Buyer</OptionsFrameHeader>
                <OptionsFrameContent>
                  We’ve found a few buyers who would purchase your shoes in bulk
                  and satisfy the restrictions you set. (International) (Buyers
                  will only sell your shoes online in overseas territories).
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Profit: <span>$4,700 </span>
                  {""}per pallet
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
          </Grid>
        </OptionsFrameBox>
      </OptionsBox>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: "20px",
        }}
      >
        <AdditionalOptionButton onClick={handleOffloadingOptions}>
          Additional Options
        </AdditionalOptionButton>
        <AnalysisButton>Analysis</AnalysisButton>
      </div>
    </>
  );
};
export default AdditionalOptions;
