import { styled } from "@mui/material";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";

export const RightPanel = styled("div")`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  .mobileLogoBox {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      padding-top: 50px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const BusiSelect = styled(Form.Control)`
  appearance: auto !important
`;

export const LeftPanel = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* max-height: max-content; */
  background-color: #dff5e4;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const NavbarBox = styled("div")`
  margin-left: -10%;
  justify-content: center !important;
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Button = styled("button")`
  justify-content: center;
  margin-top: 25px;
  max-width: 305px;
  width: 100%;
  background-color: #48b7a7;
  border: none;
  height: 38px;
  border-radius: 3px;
  color: white;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-family: "ProximaNova" !important;
  display: flex;
  font-weight: bold;
  &:disabled {
    background-color: rgb(137, 208, 198);
  }
  @media (max-width: 768px) {
    max-width: 767px;
    width: 100%;
  }
`;

export const CarouselBox = styled("div")`
  padding: 15px 0;
  display: flex;
  /* justify-content: center; */
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  .carousel-content {
    height: 100%;
    width: 450px;
    .carousel-control-next-icon {
      width: 32px;
    }
  }
`;

export const CarouselCaption = styled("div")`
  padding: 55px 0;
  display: flex;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
`;

export const CarouselItem = styled("div")`
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;
export const LoginForm = styled("div")`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  .titleLog {
    font-size: 27px;
    margin-top: 30px;
    font-family: "ProximaNova" !important;
    font-weight: bolder;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .google-login {
    border: 1px solid #dadce0 !important;
    background-color: white !important;
    border-radius: 4px !important;
    margin: 12px 0 !important;
    box-shadow: none !important;
    color: #3c4043 !important;
    font-size: 16px !important;
    font-weight: bolder !important;
    display: flex !important;
    align-items: center !important;
    div {
      margin: 0px !important;
    }
    span {
      font-family: "ProductSans-Medium" !important;
      font-size: 14px;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
    font-family: "ProximaNova" !important;
    font-size: 14px;
  }
  .form-field {
    max-width: 305px;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      max-width: 767px;
    }
  }
  .signinform {
    @media (max-width: 768px) {
      padding: 10px;
      width: 100%;
      max-width: 767px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
