import { combineReducers } from "redux";

// Authentication
import LoginReducer from "./login/reducer";
import AuthReducer from "./auth/reducer";
import { api } from "./api";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  Login: LoginReducer,
  authUser: AuthReducer,
});

export default rootReducer;
