import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GetUser from "../../Utils/Get-user";
import axios from "axios";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { questionRoute } from "../../Routes/constants";

import UPopup from "./uploadPop";
import WPopup from "./wmsPop";

import { ReactComponent as ArrowIcon } from "../../assets/images/svg/Arrow.svg";

import {
  DataUploadBox,
  DataUploadText,
  UploadBox,
  ContentText,
  ButtonBox,
  UploadButton,
} from "./styles";

const SDataUpload = () => {
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showWMSModal, setShowWMSModal] = useState(false);
  const user = GetUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'user/get_onboard_stat', { params: { userID: user } });
        if (!response.data.status) {
          navigate(questionRoute);
        }
      } catch (error) {
        console.error("Error grabbing tos data:", error);
      }
    };
  
    fetchData();
  });

  const handleClose = () => {
    setShowUploadModal(false);
  };

  const handleWMSClose = () => {
    setShowWMSModal(false);
  };

  return (
    <>
      <DataUploadBox>
        <UploadBox>
          <DataUploadText>Data Upload</DataUploadText>
          <ContentText>
            Upload your inventory data
          </ContentText>
        </UploadBox>
        <ButtonBox>
          <Dialog
            open={showUploadModal}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                overflowX: "hidden",
                maxWidth: "665px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <UPopup />
            </DialogContent>
          </Dialog>

          <Dialog
            open={showWMSModal}
            onClose={handleWMSClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                overflowX: "hidden",
                maxWidth: "665px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleWMSClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <WPopup />
            </DialogContent>
          </Dialog>

          <UploadButton onClick={() => setShowUploadModal(true)}>
            <div className="button-icon">
              {" "}
              <ArrowIcon />
            </div>
            <span className="button-text">Upload</span>
          </UploadButton>
          <br/>
          <UploadButton onClick={() => setShowWMSModal(true)}>
            <div className="button-icon">
              {" "}
              <ArrowIcon />
            </div>
            <span className="button-text">Link Storage System</span>
          </UploadButton>
        </ButtonBox>
      </DataUploadBox>
    </>
  );
};

export default SDataUpload;
