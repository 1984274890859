import React, { useState } from "react";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import { usePostForgotpasswordMutation } from "../../Redux/api/User";

import { homeRoute, RegisterRoute } from "../../Routes/constants";

import "../../assets/styling/login.css";
import { LoginButton } from "../login/styles";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword]: any = usePostForgotpasswordMutation();
  const [email, setEmail] = useState("");

  function validateForm() {
    return email.length > 0;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await forgotPassword({ email });

      toast.success("Please check your mail!!");
      navigate(homeRoute);
    } catch (err) {
      toast.error("Please type correct email");
    }
  };
  return (
    <>
      <Box className="d-flex justify-content-center align-content-center">
        <Form>
          <Form.Group className="form-field">
            <Form.Label className="title">Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <LoginButton disabled={!validateForm()} onClick={handleSubmit}>
              Forgot Password
            </LoginButton>
          </div>
          Don't have an account? <a href={RegisterRoute}>Register here</a>
        </Form>
      </Box>
    </>
  );
};
export default ForgotPassword;
