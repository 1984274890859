import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/styles";
import GetUser from "../../Utils/Get-user";
import moment from "moment";
import { getExcessobj } from "../../Utils/getexcessobj";
import { Card, CardContent, CircularProgress } from "@mui/material";

import { useGetExcessId } from "../../Redux/api/User";

import { PreviousRecomandationBox, PreviousRecomandationText, NextButton } from "./styles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
    marginTop: "20px",
  },
  table: {
    backgroundColor: "#fcfcfc",
    borderRadius: "5px !important",
    boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
  },
  cell: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000",
    fontFamily: "ProximaNova !important",
    borderRight: "1px solid #7C7C7C",
    borderTop: "1px solid #7C7C7C",
    borderBottom: "none",
    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "ProximaNova !important",
    color: "#000",
    backgroundColor: "#f3f0f0",
    borderRight: "1px solid #7C7C7C",
    borderBottom: "1px solid #7C7C7C",
    "&:last-child": {
      borderRight: "none",
    },
  },
}));
const TrackOffers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listingItem, setListingItem] = useState([]);
  const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [makeOffer, setMakeOffer] = useState(false);
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(10);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState("dateEnd");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  
  var total_cost = 0;
  var tax_rate = 0;

  var params = {}
  const expDateCheck = true;

  const currDate = new Date();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'offer/get_user_offer', { params: { userID: GetUser()} });
        setOffers(response.data.offer);
        setMadeOffer(response.data.offerFound);
        setIsLoading(false);
        
      } catch (error) {
        console.error("Error grabbing listing:", error);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(offers.slice(startFoundIndex, endFoundIndex));
  }, [offers, foundPage, foundRowsPerPage]);


  const handleFoundChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleSeeOffer = (id: string) => {
    
    navigate('/review-offer?o=' + id)
  }

  const sortedData = offers.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  const handleRequestSort = (property: string) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const showStatus = (status: string) => {
    if (status === "IP") {
      return (<span> In Progress </span>)
    } else if (status === "offered") {
      return (<span> Offered </span>)
    } else if (status === "ACPT") {
      return (<span> Accepted </span>)
    } else if (status === "ACPTN") {
      return (<span> Accepted - Negotiated </span>)
    } else if (status === "REJ") {
      return (<span> Rejected </span>)
    } else {
      return (<span> Needs Update </span>)
    }
  }

  const showAmount = (amount: any, status: any) => {
    if(amount.length > 0) {
      const curr_amount = amount[amount.length - 1].toLocaleString(undefined, { minimumFractionDigits: 2 })
      if(status === "REJ") {
        return (<span> Rejected </span>)
      } else {
        return (<span> ${curr_amount}</span>)
      }

    } else {
      return (<span> Pending </span>)
    }
  }

  return (
    <>
    <PreviousRecomandationBox>
        <PreviousRecomandationText>
          Current Offers{" "}
        </PreviousRecomandationText>
        {isLoading ? (
          <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
            <CardContent className="pt-1 d-flex justify-content-center">
              <CircularProgress sx={{ color: "#41c175" }} />
            </CardContent>
          </Card>
        ) : (
          <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.header}>
                    Status
                  </TableCell>
                  <TableCell align="center" className={classes.header} sortDirection={orderBy === "dateStart" ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'dateStart'}
                      direction={orderBy === 'dateStart' ? order : 'asc'}
                      onClick={handleRequestSort('dateStart')}
                    >
                    Date Start
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className={classes.header} sortDirection={orderBy === "dateEnd" ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'dateEnd'}
                      direction={orderBy === 'dateEnd' ? order : 'asc'}
                      onClick={handleRequestSort('dateEnd')}
                    >
                    Date End
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className={classes.header}>
                    Offer Amount (Current)
                  </TableCell>
                  {/** 
                  <TableCell align="center" className={classes.header}>
                    Listings
                  </TableCell>
                  */}
                  <TableCell align="center" className={classes.header}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.slice(foundPage * foundRowsPerPage, foundPage * foundRowsPerPage + foundRowsPerPage)?.map((offer: any, index: Number) => (
                  <TableRow>
                    <TableCell align="center" className={classes.cell}>
                      {showStatus(offer.status)}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {moment(offer.dateStart).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {moment(offer.dateEnd).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {showAmount(offer.offerAmounts, offer.status)}
                      {/** 
                      {offer.offerAmounts.length > 0 ? "$" + offer.offerAmounts[offer.offerAmounts.length - 1].toLocaleString(undefined, { minimumFractionDigits: 2 }) : 'Pending' }
                      */}
                    </TableCell>
                    {/** 
                    <TableCell align="center" className={classes.cell}>
                       See Included Listings
                    </TableCell>
                    */}
                    <TableCell align="center" className={classes.cell}>
                      <NextButton onClick={() => handleSeeOffer(offer._id)}> See Full Offer </NextButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={offers.length}
          rowsPerPage={foundRowsPerPage}
          page={foundPage}
          onPageChange={handleFoundChangePage}
          onRowsPerPageChange={handleFoundChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              marginBottom: '0rem', 
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: '0rem',
            },
          }}
        />
        </>
          
        )}
        </PreviousRecomandationBox>
    </>
  );
};
export default TrackOffers;
