import { usePostMutation, useLazyGetQuery, useGetQuery } from "../index";
import { EXCESSDATA } from "../../tagTypes/index";

interface LoginDetail {
  email: string;
  password: string;
}
interface RegisterDetail {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
interface forgotPassword {
  email: string;
}

interface QuestionAnswer {
  productType: string[];
  timeline: string;
  value: string;
  options: string[];
  reason: string[];
  date: any;
}

interface ExcessFile {
  userID: string;
  fileName: string;
  excessID: string;
}

interface BusinessInfo {
  company: string;
  link: string;
  userID: string;
}

export const usePostLoginMutation = () => {
  const [loginUser, data] = usePostMutation();
  const login = async (payload: LoginDetail, query = {}) => {
    return await loginUser({
      endpoint: "login",
      query,
      payload: payload || {},
    }).unwrap();
  };

  return [login, data];
};

export const usePostGoogleLoginMutation = () => {
  const [googleloginUser, data] = usePostMutation();
  const googlelogin = async (payload: LoginDetail) => {
    return await googleloginUser({
      endpoint: "login/google_login",
      payload: payload || {},
    }).unwrap();
  };

  return [googlelogin, data];
};

export const usePostRegisterMutation = () => {
  const [registerUser, data] = usePostMutation();
  const register: any = async (payload: RegisterDetail, query = {}) => {
    return await registerUser({
      endpoint: "signup",
      query,
      payload: payload || {},
    }).unwrap();
  };

  return [register, data];
};

export const usePostForgotpasswordMutation = () => {
  const [forgotpassword, data] = usePostMutation();
  const forgotPassword = async (payload: forgotPassword, query = {}) => {
    return await forgotpassword({
      endpoint: "resetPasswordEmail",
      query,
      payload: payload || {},
    }).unwrap();
  };

  return [forgotPassword, data];
};

export const useLazyGetUserNameQuery = () => {
  const [getUsername, data] = useLazyGetQuery();
  const getuserName = async (userID: string) =>
    await getUsername(
      {
        endpoint: `user/get_name?userID=${userID}`,
      },
      true
    ).unwrap();
  return [getuserName, data];
};

export const usePostAddBusinessInfo = () => {
  const [addBusinessinfo, data] = usePostMutation();
  const addBusinessInfo = async (payload: BusinessInfo) => {
    return await addBusinessinfo({
      endpoint: `user/update_info`,

      payload: payload || {},
    }).unwrap();
  };

  return [addBusinessInfo, data];
};

export const useLazyGetBusinessinfo = () => {
  const [getBusinessinfo, data] = useLazyGetQuery();
  const getBusinessInfo = async (userID: string) =>
    await getBusinessinfo(
      {
        endpoint: `user/get_busi_info?userID=${userID}`,
      },
      true
    ).unwrap();
  return [getBusinessInfo, data];
};

export const usePostQuestionAnswerMutation = () => {
  const [Question, data] = usePostMutation();
  const QuestionAnswer = async (payload: QuestionAnswer, query = {}) => {
    return await Question({
      endpoint: "excess/add_excess",
      payload: payload || {},
      tags: [EXCESSDATA],
    }).unwrap();
  };

  return [QuestionAnswer, data];
};

export const usePostAddExcessFile = () => {
  const [excessFile, data] = usePostMutation();
  const AddExcessFile = async (payload: ExcessFile) => {
    return await excessFile({
      endpoint: "excess/add_excess_file",
      payload: payload || {},
      tags: [EXCESSDATA],
    }).unwrap();
  };

  return [AddExcessFile, data];
};

export const useGetExcessId = (userID: string) => {
  return useGetQuery({
    endpoint: `excess/get_excess?userID=${userID}`,
    tags: [EXCESSDATA],
  });
};

export const useGetOnBoarding = (userID: string) => {
  return useGetQuery({
    endpoint: `user/get_onboarding?userID=${userID}`,
    tags: [EXCESSDATA],
  });
};

export const useSearchListings = (userID: string, upc :any[]) => {
  const query = { userID, upc}
  return useGetQuery({
    endpoint: `listing/search_listing`,
    query,
    tags: [EXCESSDATA],
  });
};
