import React, { useState, useRef, CSSProperties } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import aws from "aws-sdk";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";

import GetUser from "../../Utils/Get-user";
import { usePostAddExcessFile, useGetExcessId } from "../../Redux/api/User";
import { useNavigate } from "react-router-dom";
import { buyerSearchResultsRoute } from "../../Routes/constants";

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { Popover, Typography } from "@material-ui/core";

import {
    ConfirmationText,
} from "./styles";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

export default function CPopup() {
  
  return (
    <>
     <ConfirmationText> Offer Sent! </ConfirmationText>
     <br/>
     <ConfirmationText> Once the supplier approves, you will receive an email with next steps.</ConfirmationText>
    </>
  );
}